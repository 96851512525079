import React, { useState } from 'react';
import { useEffect } from 'react';
import ReactExport from 'react-export-excel';
import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';
import dateformat from 'utils/dateformat';
import { Button } from '@material-ui/core';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Download = (props) => {
  const { data } = props;

  const [values, setValues] = useState([]);

  useEffect(() => {
    let nseries = data.map((item) => item.serialnumber);
    axios(process.env.REACT_APP_API_MOTOMCO, getToken())
      .post('/dashboard/activelogsfilter', { filter: nseries })
      .then((result) => {
        let data = result.data.map((item) => {
          item.data = dateformat(item.data);
          return item;
        });
        setValues(data);
      })
      .catch(() => { });
  }, []);

  return (
    <div>
      {values.length > 0 ? (
        <ExcelFile element={<Button variant='contained' color='primary'>Exportar Planilha</Button>}>
          <ExcelSheet data={values} name="Relatório">
            <ExcelColumn label="Data" value="data" />
            <ExcelColumn label="Número Serial" value="serialnumber" />
            <ExcelColumn label="Versão de Software" value="versaosw" />
            <ExcelColumn label="Nome" value="name" />
            <ExcelColumn label="Email" value="email" />
            <ExcelColumn label="CPF" value="cpfcnpj" />
            <ExcelColumn label="Fone" value="fone" />
            <ExcelColumn label="Endereço" value="address" />
            <ExcelColumn label="Latitude" value="lat" />
            <ExcelColumn label="Longitude" value="lng" />
          </ExcelSheet>
        </ExcelFile>
      ) : null}
    </div>
  );
};

export default Download;
