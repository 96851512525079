import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { Alert, SearchLocationInput } from 'components';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Divider,
  Button
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '30px'
  },
  alert: {
    marginBottom: theme.spacing(3)
  },
  formGroup: {
    marginBottom: theme.spacing(3)
  }
}));

const Form = props => {
  const {
    className,
    alertMessage,
    fieldChange,
    onSubmit,
    units,
    setLocation,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <React.Fragment>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Divider />
        <CardContent>
          <form>
            {alertMessage.message && (
              <Alert
                className={classes.alert}
                message={alertMessage.message}
                variant={alertMessage.type}
              />
            )}

            <div className={classes.formGroup}>
              <TextField
                autoComplete="off"
                fullWidth
                label="Nome da Empresa"
                name="name"
                onChange={event =>
                  fieldChange(event, 'name', event.target.value)
                }
                value={units.name}
                variant="outlined"
              />
            </div>
            <div className={classes.formGroup}>
              <TextField
                autoComplete="off"
                fullWidth
                label="CNPJ"
                name="cnpj"
                onChange={event =>
                  fieldChange(event, 'cnpj', event.target.value)
                }
                value={units.cnpj}
                variant="outlined"
              />
            </div>

            <div className={classes.formGroup}>
              <SearchLocationInput
                setLocation={setLocation}
                addr={units.address}
              />
            </div>

            <div className={classes.formGroup}>
              <TextField
                autoComplete="off"
                fullWidth
                label="Telefone"
                name="fone"
                onChange={event =>
                  fieldChange(event, 'fone', event.target.value)
                }
                value={units.fone}
                variant="outlined"
              />
            </div>
            <div className={classes.formGroup}>
              <TextField
                autoComplete="off"
                fullWidth
                label="E-mail"
                name="email"
                onChange={event =>
                  fieldChange(event, 'email', event.target.value)
                }
                value={units.email}
                variant="outlined"
              />
            </div>
          </form>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.saveButton}
            color="primary"
            onClick={() => onSubmit()}
            variant="contained"
          >
            Salvar
          </Button>
          <Button
            color="secondary"
            component={RouterLink}
            to={'/units'}
            variant="contained"
          >
            Cancelar
          </Button>
        </CardActions>
      </Card>
    </React.Fragment>
  );
};

Form.propTypes = {
  alertMessage: PropTypes.object,
  cities: PropTypes.object,
  className: PropTypes.string,
  fieldChange: PropTypes.object,
  onSubmit: PropTypes.func,
  states: PropTypes.object,
  units: PropTypes.object
};

export default Form;
