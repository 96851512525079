/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

import DashboardIcon from '@material-ui/icons/Dashboard';
import MapIcon from '@material-ui/icons/Map';
import Business from '@material-ui/icons/Business';
import Work from '@material-ui/icons/Work';
import Inbox from '@material-ui/icons/Inbox';
import Assignment from '@material-ui/icons/Assignment';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import PasswordIcon from '@material-ui/icons/Lock';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import ContactSupport from '@material-ui/icons/ContactSupport';
import Notifications from '@material-ui/icons/Send';
import InsertChartIcon from '@material-ui/icons/InsertChart';

export default [
  {
    pages: [
      {
        page: 'home',
        title: 'Home',
        href: '/home',
        icon: DashboardIcon
      },
      {
        page: 'users',
        title: 'Usuários',
        href: '/users',
        icon: PersonIcon
      },
      {
        page: 'cooperatives',
        title: 'Cooperativas',
        href: '/cooperatives',
        icon: Work
      },
      {
        page: 'units',
        title: 'Unidades',
        href: '/units',
        icon: Business
      },
      {
        page: 'equipments',
        title: 'Equipamentos',
        href: '/equipments',
        icon: Inbox
      },
      {
        page: 'maps',
        title: 'Mapa',
        href: '/maps',
        icon: MapIcon
      },
      {
        page: 'charts',
        title: 'Curvas',
        href: '/charts',
        icon: InsertChartIcon
      },
      {
        page: 'support',
        title: 'Suporte',
        href: '/support',
        icon: ContactSupport
      },
      {
        page: 'equipmentsQL',
        title: 'Qualidade',
        href: '/equipmentsQL',
        icon: Assignment
      },
      {
        page: 'notifications',
        title: 'Notificações',
        href: '/notifications',
        icon: Notifications
      },
      {
        page: 'userspassword',
        title: 'Gera Senha',
        href: '/userspassword',
        icon: PasswordIcon
      },
      {
        page: 'general',
        title: 'Configurações',
        href: '/settings/general',
        icon: SettingsIcon
      },
      {
        page: 'logout',
        title: 'Sair',
        href: '/logout',
        icon: LogoutIcon,
      }
    ]
  }
];
