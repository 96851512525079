import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import Checkbox from '@material-ui/core/Checkbox';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';

import getInitials from 'utils/getInitials';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  rmvButton: {
    borderColor: '#FE2020',
    color: '#FE2020',
    marginLeft: '10px',
    background: 'transparent'
  }
}));

const Results = (props) => {
  const { className, data, onRemoveButton, permission, ...rest } = props;

  const classes = useStyles();

  const [selectedData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {data.length} registros encontrados. Página {page + 1} de{' '}
        {Math.ceil(data.length / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader title="Cooperativas" />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className='col-sm-3'>NOME</TableCell>
                    <TableCell align="center">CNPJ</TableCell>
                    <TableCell align="center">CROSSCHECK</TableCell>
                    <TableCell align="center">UNIDADES</TableCell>
                    <TableCell align="center">EQUIPAMENTOS</TableCell>
                    <TableCell align="center" className='col-sm-2'>AÇÃO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                      .map((data) => (
                        <TableRow
                          hover
                          key={data._id}
                          selected={selectedData.indexOf(data._id) !== -1}>
                          <TableCell>
                            <div className={classes.nameCell}>
                              <Avatar
                                className={classes.avatar}
                                src={data.avatar}>
                                {getInitials(data.name)}
                              </Avatar>
                              <div>
                                <Link
                                  color="inherit"
                                  component={RouterLink}
                                  to={`/cooperatives/${data._id}`}
                                  variant="h6">
                                  {data.name ? data.name : '---'}
                                </Link>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell align="center">{data.cnpj}</TableCell>
                          <TableCell align="center">
                            <Checkbox
                              checked={data.crossCheck ? true : false}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              disabled
                            />
                          </TableCell>
                          <TableCell align="center">{data.units.length}</TableCell>
                          <TableCell align="center">{data.equipments.length}</TableCell>
                          <TableCell align="center">
                            <Button
                              color="secondary"
                              component={RouterLink}
                              size="small"
                              to={`/cooperatives/${data._id}`}
                              variant="outlined">
                              Visualizar
                            </Button>
                            {permission &&
                              <Button
                                className={classes.rmvButton}
                                onClick={() => onRemoveButton(data._id)}
                                size="small"
                                variant="outlined"
                                disabled={permission === true ? false : true}>
                                Remover
                              </Button>
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={data?.length || 0}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count} registros`
            }
            labelRowsPerPage="Linhas por página:"
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 20, 40]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired
};

Results.defaultProps = {
  data: []
};

export default Results;
