import React, { createContext, useEffect, useState, useContext } from 'react';
import Notification from 'components/Notification/notification';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [msg, setMsg] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
    const maxReconnectAttempts = 5;

    useEffect(() => {
        const askNotificationPermission = () => {
            if (Notification.permission === 'default') {
                Notification.requestPermission().then(permission => {
                    if (permission === 'granted') {
                        console.log("Permissão para notificações concedida.");
                    } else {
                        console.log("Permissão para notificações negada.");
                    }
                });
            }
        };
        askNotificationPermission();

        const connectWebSocket = () => {
            const ws = new WebSocket(process.env.REACT_APP_API_MONITORA.replace('https', 'wss').replace('http', 'ws'));

            const user = JSON.parse(localStorage.getItem('userData'));

            let pingInterval;

            ws.onopen = () => {
                setSocket(ws);
                setIsConnected(true);
                setReconnectAttempts(0);

                const now = new Date();
                const time = now.toISOString().replace(/T/, ' ').replace(/\..+/, ''); // Formata como YYYY-MM-DD HH:mm:ss

                let connectUser = user ? `${user.email}-API-${time}` : `API-${time}`

                ws.send(
                    JSON.stringify({
                        type: 'join',
                        room: 'support',
                        content: { name: connectUser },
                    }));

                pingInterval = setInterval(() => {
                    if (ws.readyState === WebSocket.OPEN) {
                        ws.send(JSON.stringify({ type: 'ping' }));
                    }
                }, 20000);
            };

            ws.onmessage = (event) => {
                try {
                    const novaMsg = JSON.parse(event.data);
                    if (novaMsg.type === "support" && novaMsg?.content?.serial && novaMsg?.content?.message) {
                        if (user?.permission?.support?.read) {
                            setMsg(novaMsg.content)

                            if (Notification.permission === 'granted') {
                                const messageContent = novaMsg.content.message;
                                const serialContent = novaMsg.content.serial;

                                const notificationOptions = {
                                    body: messageContent.length > 100 ? messageContent.substring(0, 100) + '...' : messageContent,
                                    icon: '/images/ic_alert.png',
                                };

                                new Notification(`Alerta de suporte! - Serial: ${serialContent}`, notificationOptions);
                            }

                        }
                    }
                } catch (error) {
                    console.error('Erro ao processar a mensagem:', error);
                }
            };

            ws.onerror = (error) => {
                console.error('Erro no WebSocket:', error);
            };

            ws.onclose = (event) => {
                console.log(`WebSocket desconectado: ${event.code} - ${event.reason}`);
                setIsConnected(false)
                clearInterval(pingInterval);

                if (reconnectAttempts < maxReconnectAttempts) {
                    const timeout = Math.pow(2, reconnectAttempts) * 1000; // Exponencial Backoff
                    setReconnectAttempts(prev => prev + 1); // Incrementa o número de tentativas de reconexão
                    setTimeout(connectWebSocket, timeout);
                } else {
                    console.error('Máximo de tentativas de reconexão alcançado.');
                }
            };

            return () => {
                if (ws.readyState === WebSocket.OPEN) {
                    clearInterval(pingInterval);
                    ws.close(1000, 'Componente desmontado');
                }
            };
        }
        connectWebSocket();

        return () => {
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.close(1000, 'Componente desmontado');
            }
        };
    }, []);

    return (
        <WebSocketContext.Provider value={socket}>
            {msg && <Notification data={msg} />}
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(WebSocketContext);
