import React, { useState, useEffect } from 'react';

import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';

import { Page, SearchBar } from 'components';
import { Header, Results } from './components';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from 'react-bootstrap/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  }
}));

const User = () => {
  const classes = useStyles();

  const user = JSON.parse(localStorage.getItem('userData'))
  if (user && user.permission.users.read === false) {
    window.location.replace('/home')
  }

  const [users, setUsers] = useState([]);
  const [tempUsers, setTempUsers] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openAuth, setOpenAuth] = React.useState(false);
  const [openAuthPassword, setOpenAuthPassword] = React.useState(false);
  const [searchData, setSearchData] = useState('');
  const [userId, setUserId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [permission, setPermission] = useState(false);
  const [notAutenticated, setNotAutenticated] = useState(null);
  let [loading, setLoading] = useState(false);

  const handleClickOpen = (id) => {
    setUserId(id);
    setOpen(true);
  };

  const handleClickOpenAuthPassword = (id) => {
    setUserId(id);
    setOpenAuthPassword(true);
  };

  const handleClose = () => {
    setUserId('');
    setOpen(false);
  };

  const handleClickOpenAuth = (email) => {
    setUserEmail(email);
    setOpenAuth(true);
  };

  const handleCloseAuth = () => {
    setUserEmail('');
    setOpenAuth(false);
  };

  const handleCloseAuthPassword = () => {
    setOpenAuthPassword(false);
  };

  const getUsers = async () => {
    setSearchData('')
    setLoading(true)
    try {
      var { data: users } = await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).get('/users');
      users = users.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
      setUsers(users);
      setTempUsers(users);
      setLoading(false);

      let notAutenticated = users.filter((item) => {
        return !item.validated;
      }).length;

      setNotAutenticated(notAutenticated);
    } catch (error) {
      setLoading(false);
      alert("Erro", error)
    }
  }

  const getUsersFilter = async () => {
    if (!searchData) {
      alert("Digite um filtro.")
      return
    }
    setLoading(true);
    try {
      var { data: users } = await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).get('/getusersfilter', { "params": { "filter": searchData } });
      users = users.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
      setUsers(users);
      setTempUsers(users);
      setLoading(false);

      let notAutenticated = users.filter((item) => {
        return !item.validated;
      }).length;

      setNotAutenticated(notAutenticated);
    } catch (error) {
      setLoading(false);
      alert("Erro", error)
    }
  }

  const removeUser = () => {
    (async () => {
      setOpen(false);
      console.log(userId);
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).delete(
        `/users/${userId}`
      );
      getUsers();
    })();
  };

  const authUser = () => {
    (async () => {
      setOpenAuth(false);
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post(
        '/validateemailforced',
        { "email": userEmail }
      );
      getUsers();
    })();
  };

  const authUserPassword = () => {
    (async () => {
      setOpenAuthPassword(false);
      let data = JSON.parse(localStorage.getItem('userData'));
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post(
        '/passwordgeneratepermission',
        {
          "email": data.email,
          "password": data.password,
          "id": userId
        }
      );
      alert('Permissão adicionada');
    })();
  };

  useEffect(() => {
    const { permission } = JSON.parse(localStorage.getItem('userData'));
    setPermission(permission.users.write);
    getUsers();
  }, []);

  const handleFilter = () => { };

  const handleSearch = () => {
    if (searchData === '') {
      setTempUsers(users);
    } else {
      const usersSearch = [];
      users.forEach((user) => {
        if (
          (user.name && user.name.toUpperCase().includes(searchData.toUpperCase())) ||
          (user.email && user.email.toUpperCase().includes(searchData.toUpperCase())) ||
          (user.cpfcnpj && user.cpfcnpj.toUpperCase().includes(searchData.toUpperCase()))
        ) {
          usersSearch.push(user);
        }
      });
      setTempUsers(usersSearch);
    }
  };

  const handleSearchData = (e) => {
    setSearchData(e.target.value);
  };

  return (
    <Page className={classes.root} title="Usuários">
      <Header />

      <div className="row">
        <div className="col mt-2">
          <SearchBar
            onFilter={handleFilter}
            onSearch={handleSearch}
            onSearchData={handleSearchData}
            searchData={searchData}
          />

          <Button
            style={{ "width": "250px", "marginRight": "15px" }}
            className="mt-3"
            color="primary"
            onClick={() => { getUsersFilter() }}
            variant="contained"
          >
            Buscar Filtro
          </Button>

          <Button
            style={{ "width": "250px" }}
            className="mt-3"
            color="primary"
            onClick={() => { getUsers() }}
            variant="contained"
          >
            Buscar Todos
          </Button>
        </div>
      </div>

      {notAutenticated && notAutenticated > 0 && (
        <Alert variant="danger" className='mt-4 mb-4 p-2'>
          <p style={{ marginBottom: 0 }}>
            Existem {notAutenticated} usuários aguardando validação.
          </p>
        </Alert>
      )}

      {loading ? (
        <div style={{ textAlign: 'center', marginTop: 30 }}>
          <CircularProgress />
        </div>
      ) : null}

      {tempUsers && (
        <Results
          permission={permission}
          className={classes.results}
          data={tempUsers}
          onRemoveButton={handleClickOpen}
          onAuthorizeButton={handleClickOpenAuth}
          onAuthorizeGeneratePassword={handleClickOpenAuthPassword}
        />
      )}

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleClose}
        open={open}>
        <DialogTitle id="alert-dialog-title">{'Remover'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja realmente remover esse usuário?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Não
          </Button>
          <Button autoFocus color="primary" onClick={removeUser}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCloseAuth}
        open={openAuth}>
        <DialogTitle id="alert-dialog-title">{'Autorizar'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja autorizar o e-mail desse usuário?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCloseAuth}>
            Não
          </Button>
          <Button autoFocus color="primary" onClick={authUser}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCloseAuthPassword}
        open={openAuthPassword}>
        <DialogTitle id="alert-dialog-title">
          {'Autorizar Gera Senha'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja autorizar este usuário a gerar senhas para acesso ao
            equipamento Motomco?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCloseAuthPassword}>
            Não
          </Button>
          <Button autoFocus color="primary" onClick={authUserPassword}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default User;
