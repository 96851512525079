/* eslint-disable no-unused-vars */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Button, Toolbar, Hidden, colors } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Menu';
import useRouter from 'utils/useRouter';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#292E29',
  }
}));

const TopBar = props => {
  const { openNavBar, className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <div style={{ padding: '10px' }}>
        <InputIcon
          style={{ marginTop: '8px', marginLeft: '10px' }}
          onClick={openNavBar}
        />
      </div>
    </AppBar>
  );
};

export default TopBar;
