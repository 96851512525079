import React, { useState, useEffect } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Alert } from 'components';

import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  TextField,
  colors
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  alert: {
    marginBottom: '30px;'
  }
}));

const MapSettings = props => {
  const { profile, className, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    greaterThan: '13.5',
    lowerThan: '16'
  });

  const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });

  useEffect(() => {
    const { map } = JSON.parse(localStorage.getItem('userData'));
    setValues(map);
  }, []);

  const handleChange = event => {
    event.persist();

    setValues({
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      let userData = JSON.parse(localStorage.getItem('userData'));
      userData.map = {};
      userData.map.greaterThan = values.greaterThan;
      userData.map.lowerThan = values.lowerThan;

      localStorage.setItem('userData', JSON.stringify(userData));

      setAlertMessage({ message: 'Atualizado com suceso!', type: 'success' });
    } catch (e) {
      setAlertMessage({ message: 'Falha ao atualizar!', type: 'error' });
    }
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form onSubmit={handleSubmit}>
        <CardHeader title="Mapa" />
        <Divider />
        <CardContent>
          {alertMessage.message && (
            <Alert
              className={classes.alert}
              message={alertMessage.message}
              variant={alertMessage.type}
            />
          )}

          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <TextField
                autoComplete="off"
                fullWidth
                label="Valor de corte inferior (Ex.: 8.1)"
                name="greaterThan"
                onChange={handleChange}
                required
                value={values.greaterThan}
                variant="outlined"
                helperText={`Valores de umidade abaixo de ${values.greaterThan} mostrarão o pin na cor vermelha, enquanto os valores entre ${values.greaterThan} e ${values.lowerThan} mostrarão o pin na cor verde.`}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                autoComplete="off"
                fullWidth
                label="Valor de corte superior (Ex.: 16.5)"
                name="lowerThan"
                onChange={handleChange}
                required
                value={values.lowerThan}
                variant="outlined"
                helperText={`Valores de umidade acima de ${values.lowerThan} mostrarão o pin na cor amarela.`}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.saveButton}
            type="submit"
            variant="contained">
            Salvar
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

MapSettings.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object.isRequired
};

export default MapSettings;
