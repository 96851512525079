import React from 'react';

import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { Page } from 'components';
import { Header, General } from './components';

import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  },
  indicator: {
    backgroundColor: colors.green[500]
  }
}));

const Settings = (props) => {
  const { match } = props;
  const classes = useStyles();

  const user = JSON.parse(localStorage.getItem('userData'))
  if (user) {
    if (user.permission.general.read === false) {
      window.location.replace('/home')
    }
  }

  const { tab } = match.params;

  const handleTabsChange = (event, value) => {
    //history.push(value);
  };

  const tabs = [
    { value: 'general', label: 'Geral' },
    // { value: 'security', label: 'Segurança' }
  ];

  if (!tab) {
    return <Redirect to="/settings/general" />;
  }

  return (
    <Page className={classes.root} title="Configuração">
      <Header />
      <Tabs
        className={classes.tabs}
        classes={{ indicator: classes.indicator }}
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        variant="scrollable">
        {tabs.map(tab => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'general' && <General />}
        {/* {tab === 'security' && <Security />} */}
      </div>
    </Page>
  );
};

Settings.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default Settings;
