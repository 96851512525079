import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import clsx from 'clsx';
import { Bar, Line } from 'react-chartjs-2';

import StorageIcon from '@material-ui/icons/Storage';
import ErrorIcon from '@material-ui/icons/Error';

import dateformat from 'utils/dateformat';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardActions,
  Grid,
  Divider,
  Button,
  Typography,
  colors
} from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  grid: {
    flexGrow: 1
  },
  control: {
    padding: theme.spacing(2)
  },
  card: {
    textAlign: 'center',
    width: 500,
    margin: theme.spacing(1)
  },
  cardfull: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  media: {
    textAlign: 'center',
    height: 50,
    width: 50
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  },
  indicator: {
    backgroundColor: colors.green[500]
  },
  text: {
    fontSize: '14pt'
  },
  hidden: {
    display: 'none'
  }
}));

const Form = (props) => {
  const {
    className,
    alertMessage,
    data,
    fieldChange,
    onSubmit,
    unities,
    handleIdChange,
    selectedId,
    ...rest
  } = props;

  const classes = useStyles();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <form>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedId}
            onChange={handleIdChange}>
            {data.testIds
              ? data.testIds.map((item) => (
                <MenuItem value={item}>{dateformat(new Date(item))}</MenuItem>
              ))
              : null}
          </Select>

          <Grid item>
            <Card className={classes.cardfull} ref={componentRef}>
              <CardContent>
                <Typography
                  className={classes.text}
                  component="h2"
                  gutterBottom
                  variant="h1">
                  LAUDO TÉCNICO DO EQUIPAMENTO
                </Typography>

                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">NÚMERO DE SÉRIE</TableCell>
                        <TableCell align="center">NÚMERO DE TESTES</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">
                          {' '}
                          {data.serialnumber}
                        </TableCell>
                        <TableCell align="center">
                          {' '}
                          {isNaN(data.numerrors + data.nummedicoes)
                            ? ''
                            : data.numerrors + data.nummedicoes}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>

              {data.numerrors + data.nummedicoes < 200 && (
                <Typography
                  variant="h4"
                  style={{ marginBottom: 20, color: 'red' }}>
                  Ainda faltam {200 - (data.numerrors + data.nummedicoes)}{' '}
                  testes para geração do laudo do equipamento.
                </Typography>
              )}

              {data.numerrors + data.nummedicoes >= 200 && (
                <CardContent>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" colSpan="7">
                            ANÁLISE DOS COMPONENTES
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">COMPONENTE</TableCell>
                          <TableCell align="center">FALHAS</TableCell>
                          <TableCell align="center">%</TableCell>
                          <TableCell align="center">OK (%)</TableCell>
                          <TableCell align="center">ALERTA (%)</TableCell>
                          <TableCell align="center">FALHA (%)</TableCell>
                          <TableCell align="center">RESULTADO</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left">Célula de carga </TableCell>
                          <TableCell align="center">
                            {' '}
                            {data.errosCelCarga}
                          </TableCell>
                          <TableCell align="center">
                            {' '}
                            {data.errosCelCargaPercent}
                          </TableCell>
                          <TableCell align="center">&lt;1 </TableCell>
                          <TableCell align="center">1 a 2 </TableCell>
                          <TableCell align="center">&gt;2 </TableCell>
                          <TableCell align="center">
                            {' '}
                            {data.errosCelCargaPercent < 1
                              ? 'OK'
                              : data.errosCelCargaPercent < 2
                                ? 'ALERTA'
                                : 'FALHA'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">
                            Posição do copo de medicão{' '}
                          </TableCell>
                          <TableCell align="center">
                            {data.errosCupMed}{' '}
                          </TableCell>
                          <TableCell align="center">
                            {' '}
                            {data.errosCupMedPercent}
                          </TableCell>
                          <TableCell align="center">&lt;2 </TableCell>
                          <TableCell align="center">2 a 5 </TableCell>
                          <TableCell align="center">&gt;5 </TableCell>
                          <TableCell align="center">
                            {' '}
                            {data.errosCupMedPercent < 2
                              ? 'OK'
                              : data.errosCupMedPercent < 5
                                ? 'ALERTA'
                                : 'FALHA'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">Servo motor </TableCell>
                          <TableCell align="center">{data.errosSM} </TableCell>
                          <TableCell align="center">
                            {' '}
                            {data.errosSMPercent}{' '}
                          </TableCell>
                          <TableCell align="center">&lt;2 </TableCell>
                          <TableCell align="center">2 a 5 </TableCell>
                          <TableCell align="center">&gt;5 </TableCell>
                          <TableCell align="center">
                            {' '}
                            {data.errosSMPercent < 2
                              ? 'OK'
                              : data.errosSMPercent < 5
                                ? 'ALERTA'
                                : 'FALHA'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">Borboleta </TableCell>
                          <TableCell align="center">
                            {data.errosBorb}{' '}
                          </TableCell>
                          <TableCell align="center">
                            {' '}
                            {data.errosBorbPercent}{' '}
                          </TableCell>
                          <TableCell align="center">&lt;2 </TableCell>
                          <TableCell align="center">2 a 5 </TableCell>
                          <TableCell align="center">&gt;5 </TableCell>
                          <TableCell align="center">
                            {data.errosBorbPercent < 2
                              ? 'OK'
                              : data.errosBorbPercent < 5
                                ? 'ALERTA'
                                : 'FALHA'}{' '}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">
                            Cabo flat osciladora{' '}
                          </TableCell>
                          <TableCell align="center">
                            {data.errosCabFlat}{' '}
                          </TableCell>
                          <TableCell align="center">
                            {' '}
                            {data.errosCabFlatPercent}
                          </TableCell>
                          <TableCell align="center">&lt;0 </TableCell>
                          <TableCell align="center">&lt;0.5 </TableCell>
                          <TableCell align="center">&gt;0.5 </TableCell>
                          <TableCell align="center">
                            {data.errosCabFlatPercent <= 0 ? 'OK' : 'FALHA'}{' '}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">Osciladora </TableCell>
                          <TableCell align="center"> {data.errosOsc}</TableCell>
                          <TableCell align="center">
                            {' '}
                            {data.errosOscPercent}
                          </TableCell>
                          <TableCell align="center">&lt;1 </TableCell>
                          <TableCell align="center">1 a 2 </TableCell>
                          <TableCell align="center">&gt;2 </TableCell>
                          <TableCell align="center">
                            {data.errosOscPercent < 1
                              ? 'OK'
                              : data.errosOscPercent < 2
                                ? 'ALERTA'
                                : 'FALHA'}{' '}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">
                            Sensor de Temperatura
                          </TableCell>
                          <TableCell align="center">
                            {' '}
                            {data.errosTemp}
                          </TableCell>
                          <TableCell align="center">
                            {' '}
                            {data.errosTempPercent}
                          </TableCell>
                          <TableCell align="center">&lt;1.5 </TableCell>
                          <TableCell align="center">1.5 a 3 </TableCell>
                          <TableCell align="center">&gt;3 </TableCell>
                          <TableCell align="center">
                            {data.errosTempPercent < 2
                              ? 'OK'
                              : data.errosTempPercent < 3
                                ? 'ALERTA'
                                : 'FALHA'}{' '}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">Tampa do Funil </TableCell>
                          <TableCell align="center">
                            {' '}
                            {data.errosTampFun}
                          </TableCell>
                          <TableCell align="center">
                            {' '}
                            {data.errosTampFunPercent}
                          </TableCell>
                          <TableCell align="center">&lt;2 </TableCell>
                          <TableCell align="center">2 a 5 </TableCell>
                          <TableCell align="center">&gt;5 </TableCell>
                          <TableCell align="center">
                            {' '}
                            {data.errosTampFunPercent < 2
                              ? 'OK'
                              : data.errosTampFunPercent < 5
                                ? 'ALERTA'
                                : 'FALHA'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">SOMA DAS FALHAS </TableCell>
                          <TableCell align="center">{data.sumFail} </TableCell>
                          <TableCell align="center">
                            {data.sumFailPercent}{' '}
                          </TableCell>
                          <TableCell align="center">&lt;12 </TableCell>
                          <TableCell align="center">12 a 15 </TableCell>
                          <TableCell align="center">&gt;15 </TableCell>
                          <TableCell align="center">
                            {' '}
                            {data.sumFailPercent < 12
                              ? 'OK'
                              : data.sumFailPercent < 15
                                ? 'ALERTA'
                                : 'FALHA'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              )}
              {data.numerrors + data.nummedicoes >= 200 && (
                <CardContent>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">RESULTADO</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            align="center"
                            style={
                              data.errosCelCargaPercent > 2 ||
                                data.errosCupMedPercent > 5 ||
                                data.errosSMPercent > 5 ||
                                data.errosBorbPercent > 5 ||
                                data.errosCabFlatPercent > 0 ||
                                data.errosOscPercent > 2 ||
                                data.errosTempPercent > 3 ||
                                data.errosTampFunPercent > 5 ||
                                data.sumFailPercent > 15
                                ? {
                                  background: 'red',
                                  color: 'white',
                                  margin: 20
                                }
                                : {
                                  background: 'green',
                                  color: 'white',
                                  margin: 20
                                }
                            }>
                            {data.errosCelCargaPercent > 2 ||
                              data.errosCupMedPercent > 5 ||
                              data.errosSMPercent > 5 ||
                              data.errosBorbPercent > 5 ||
                              data.errosCabFlatPercent > 0 ||
                              data.errosOscPercent > 2 ||
                              data.errosTempPercent > 3 ||
                              data.errosTampFunPercent > 5 ||
                              data.sumFailPercent > 15
                              ? 'REPROVADO'
                              : 'APROVADO'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              )}
            </Card>
          </Grid>

          <CardActions>
            <Button
              className={classes.saveButton}
              color="primary"
              onClick={handlePrint}
              variant="contained">
              Imprimir
            </Button>
          </CardActions>
          <Divider />

          <div className={classes.content}>
            <Grid className={classes.grid} container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item>
                    <Card className={classes.card}>
                      <CardContent>
                        <StorageIcon className={classes.media} />
                        <Typography component="h2" gutterBottom variant="h1">
                          {data.nummedicoes ? data.nummedicoes : ""}
                        </Typography>
                        <Typography
                          className={classes.text}
                          color="textSecondary"
                          component="p"
                          variant="body2">
                          Medições
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item>
                    <Card className={classes.card}>
                      <CardContent>
                        <ErrorIcon className={classes.media} />
                        <Typography component="h2" gutterBottom variant="h1">
                          {data.numerrors}
                        </Typography>
                        <Typography
                          className={classes.text}
                          color="textSecondary"
                          component="p"
                          variant="body2">
                          Erros
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>

          <div>
            <Line data={data.umidade} options={data.optionsUmidade} />
          </div>

          <div>
            <Line data={data.temperatura} options={data.optionsTemperatura} />
          </div>

          <div>
            <Line data={data.peso} options={data.optionsPeso} />
          </div>

          <div>
            <Line data={data.freqv} options={data.optionsFreqv} />
          </div>

          <div>
            <Line data={data.freqc} options={data.optionsFreqc} />
          </div>

          <div>
            <Line data={data.global} options={data.optionsGlobal} />
          </div>

          <div>
            {data.erros ? (
              <Bar
                data={data.erros}
                options={{
                  title: {
                    display: true,
                    text: 'Erros',
                    fontSize: 20
                  },
                  legend: {
                    display: true,
                    position: 'right'
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          suggestedMin: 0,
                          stepSize: 1
                        }
                      }
                    ]
                  }
                }}
              />
            ) : null}
          </div>
        </form>
      </CardContent>
    </Card>
  );
};

export default Form;
