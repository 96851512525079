import React, { useState, useEffect } from 'react';

import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';

import { Page, SearchBar } from 'components';
import { Header, Results } from './components';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(2)
  },
  equipButton: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6)
  }
}));

const Equipments = (props) => {
  const classes = useStyles();

  const user = JSON.parse(localStorage.getItem('userData'))
  if (user) {
    if (user.permission.equipments.read === false) {
      window.location.replace('/home')
    }
  }

  const [equipments, setEquipments] = useState([]);
  const [equipmentsToShow, setEquipmentsToshow] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [searchData, setSearchData] = useState('');
  const [equipmentId, setEquipmentId] = useState('');
  const [permission, setPermission] = useState(false);
  const [loading, setLoading] = useState(true);
  const [checkOwner, setCheckOwner] = useState(false);
  const [checkLocation, setCheckLocation] = useState(false);
  const [modelSelected, setmModelSelected] = useState('SIMT');

  const [stateWifi, setStateWifi] = useState(0);
  const [statePh, setStatePh] = useState(0);

  const handleStatateWifi = () => {
    setStateWifi(stateWifi == 0 ? 1 : stateWifi == 1 ? 2 : 0)
    handleSearch(checkOwner, checkLocation, stateWifi == 0 ? 1 : stateWifi == 1 ? 2 : 0, statePh);
  };

  const handleStatatePh = () => {
    setStatePh(statePh == 0 ? 1 : statePh == 1 ? 2 : 0)
    handleSearch(checkOwner, checkLocation, stateWifi, statePh == 0 ? 1 : statePh == 1 ? 2 : 0);
  };

  const styleSelected = { background: '#00854a', color: '#d6ebe2' };
  const styleUnselected = { background: '#d6ebe2', color: '#00854a' };

  const handleClickOpen = (id) => {
    setEquipmentId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeProprietari = () => {
    if (checkOwner == false) {
      setCheckLocation(false);
    }
    setCheckOwner(!checkOwner);
    handleSearch(!checkOwner, false, stateWifi, statePh);
  }

  const handleChangeLocate = () => {
    if (checkLocation == false) {
      setCheckOwner(false);
    }
    setCheckLocation(!checkLocation);
    handleSearch(false, !checkLocation, stateWifi, statePh);
  }

  const getEquipments = async (serie) => {
    setLoading(true)
    setmModelSelected(serie);
    try {
      const { data } = await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post(`/equipment/getserie`, { "serie": serie });
      setEquipments(data.data);
      setEquipmentsToshow(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const removeEquipment = async () => {
    try {
      setOpen(false);
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post('/equipment/delete', { "_id": equipmentId });
      getEquipments(modelSelected);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const { permission } = JSON.parse(localStorage.getItem('userData'));
    setPermission(permission.units.write)
    getEquipments(modelSelected);
  }, [])

  const handleSearch = (f0, f1, f2, f3) => {
    if (f0 == null) {
      f0 = checkOwner;
    }
    if (f1 == null) {
      f1 = checkLocation;
    }
    if (f2 == null) {
      f2 = stateWifi;
    }
    if (f3 == null) {
      f3 = statePh;
    }

    if (searchData === '' && f0 === false && f1 === false && f2 === 0 && f3 === 0) {
      setEquipmentsToshow(equipments);
    } else {

      var equipmentsSearch = [...equipments];

      if (searchData != '') {
        equipmentsSearch = equipmentsSearch.filter(item =>
          item.serialnumber.toLowerCase().includes(searchData.toLowerCase())
        );
      }

      if (f0) {
        equipmentsSearch = equipmentsSearch.filter(item =>
          item.mode == "OWNER"
        );
      }

      if (f1) {
        equipmentsSearch = equipmentsSearch.filter(item =>
          item.mode == 'RENTAL'
        );
      }

      if (f2 == 1) {
        equipmentsSearch = equipmentsSearch.filter(v => v.wf == "OK")
      } else if (f2 == 2) {
        equipmentsSearch = equipmentsSearch.filter(v => v.wf != "OK")
      }

      if (f3 == 1) {
        equipmentsSearch = equipmentsSearch.filter(v => v.calph == "OK")
      } else if (f3 == 2) {
        equipmentsSearch = equipmentsSearch.filter(v => v.calph != "OK")
      }

      setEquipmentsToshow(equipmentsSearch);
    }
  };

  const handleSearchData = (e) => {
    setSearchData(e.target.value);
  };

  return (
    <Page className={classes.root} title="Equipamentos">
      <Header />
      <SearchBar
        onSearch={() => handleSearch(checkOwner, checkLocation, stateWifi, statePh)}
        onSearchData={handleSearchData}
        searchData={searchData}
      />

      <Button
        className={classes.equipButton}
        onClick={() => getEquipments('SIMT')}
        size="large"
        style={modelSelected == "SIMT" ? styleSelected : styleUnselected}
        variant="contained"
      >
        SIMT
      </Button>

      <Button
        className={classes.equipButton}
        onClick={() => getEquipments('BIMT')}
        size="large"
        style={modelSelected == "BIMT" ? styleSelected : styleUnselected}
        variant="contained">
        BIMT
      </Button>

      <Button
        className={classes.equipButton}
        onClick={() => getEquipments('CPMT')}
        size="large"
        style={modelSelected == "CPMT" ? styleSelected : styleUnselected}
        variant="contained">
        CPMT
      </Button>

      <Button
        className={classes.equipButton}
        onClick={() => getEquipments('EEMT')}
        size="large"
        style={modelSelected == "EEMT" ? styleSelected : styleUnselected}
        variant="contained">
        EEMT
      </Button>

      <Button
        className={classes.equipButton}
        onClick={() => getEquipments('ESMR')}
        size="large"
        style={modelSelected == "ESMR" ? styleSelected : styleUnselected}
        variant="contained">
        ESMR
      </Button>

      <div style={{ width: '100%', display: 'flex' }}>

        <div>
          <Checkbox
            checked={checkOwner}
            onChange={handleChangeProprietari}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Typography variant="inherit">Proprietário</Typography>
        </div>
        <div>
          <Checkbox
            checked={checkLocation}
            onChange={handleChangeLocate}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Typography variant="inherit">Locação</Typography>
        </div>

        {(modelSelected == 'SIMT' || modelSelected == 'BIMT') && <div>
          <Checkbox
            checked={stateWifi == 1 ? true : stateWifi == 2 ? false : null}
            indeterminate={stateWifi == 0}
            onChange={handleStatateWifi}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Typography variant="inherit">Wifi</Typography>
        </div>}

        {modelSelected == 'SIMT' && <div>
          <Checkbox
            checked={statePh == 1 ? true : statePh == 2 ? false : null}
            indeterminate={statePh == 0}
            onChange={handleStatatePh}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Typography variant="inherit">PH Calibrado</Typography>
        </div>}

      </div>

      {loading ? (
        <div style={{ textAlign: 'center', marginTop: 30 }}>
          <CircularProgress />
        </div>
      ) : null}

      {equipments && (
        <Results
          className={classes.results}
          data={equipmentsToShow}
          onRemoveButton={handleClickOpen}
          permission={permission}
        />
      )}

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleClose}
        open={open}>
        <DialogTitle id="alert-dialog-title">{'Remover'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja realmente remover esse equipamento?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Não
          </Button>
          <Button autoFocus color="primary" onClick={removeEquipment}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </Page >
  );
};

export default Equipments;
