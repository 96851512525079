import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Alert } from 'components';
import { getToken } from 'actions/sessionActions';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  TextField,
  Grid,
  Divider,
  Button,
  Typography,
  Checkbox,
  Link,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import axios from 'utils/axios';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '30px'
  },
  alert: {
    marginBottom: theme.spacing(3)
  },
  formGroup: {
    marginBottom: theme.spacing(3)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  fieldHint: {
    margin: theme.spacing(1, 0)
  },
  tags: {
    marginTop: theme.spacing(1),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  dateField: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  textField: {
    margin: theme.spacing(1)
  },
  label: {
    margin: theme.spacing(2),
    fontSize: 21
  }
}));

const Form = (props) => {
  const {
    className,
    alertMessage,
    equipments,
    fieldChange,
    onSubmit,
    unities,
    permission,
    checkboxChange,
    checkboxIsentChange,
    setAlertMessage,
    empresa,
    handlePlan,
    ...rest
  } = props;

  const [enable, setEnable] = useState(false)
  const [link, setLink] = useState('')

  const handlePlanChange = (event) => {
    handlePlan(event.target.value)
  };

  useEffect(() => {
    if (empresa && empresa.typeCompany) {
      setLink(`/cooperatives/${empresa._id}`)
    } else {
      setLink(`/units/${empresa._id}`)
    }
  }, [empresa])

  const handleChange = (event) => {
    if (event.target.checked === true) {
      if (equipments.serialnumber.includes("SIMT")) {
        fieldChange(event, 'daystoactivate', 28)
        handleDaysChange(28)
      }
      if (equipments.serialnumber.includes("BIMT") || equipments.serialnumber.includes("CPMT")) {
        fieldChange(event, 'daystoactivate', 1)
        handleDaysChange(1)
      }
      if (equipments.serialnumber.includes("EEMT")) {
        fieldChange(event, 'daystoactivate', 5)
        handleDaysChange(5)
      }
    }
    checkboxChange(event.target.checked);
  };

  const handleDaysChange = async (data) => {
    try {
      await axios(process.env.REACT_APP_API_GLOBAL_SERVER, getToken()).post(
        `/equipment/updatedaysactivation`,
        {
          serialnumber: equipments.serialnumber,
          daystoactivate: data
            .toString(16)
            .toUpperCase()
        }
      );
      setAlertMessage({ message: 'Atualizado com Sucesso!', type: 'success' });
    } catch (e) {
      setAlertMessage({ message: 'Falha ao atualizar!', type: 'error' });
    }
  }

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <form>
          {alertMessage.message ?
            <Alert
              className={classes.alert}
              message={alertMessage.message}
              variant={alertMessage.type}
            />
            : null
          }
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <div className={classes.formGroup}>

                <label className={classes.label}>Empresa: </label>

                {empresa ?
                  <Link href={link} className={classes.link}>
                    {empresa.name}
                  </Link>
                  : '---'
                }

                <TextField
                  className={classes.textField}
                  aria-readonly="true"
                  autoComplete="off"
                  fullWidth
                  label="Número de Série"
                  name="serialnumber"
                  value={equipments.serialnumber ? equipments.serialnumber : ''}
                  variant="outlined"
                  disabled={true}
                />
                <TextField
                  className={classes.textField}
                  aria-readonly="true"
                  autoComplete="off"
                  fullWidth
                  label="ID"
                  name="id"
                  value={equipments.equipid ? equipments.equipid : ''}
                  variant="outlined"
                  disabled={true}
                />

                <TextField
                  className={classes.textField}
                  aria-readonly="true"
                  fullWidth
                  label="SHA1 da Curva"
                  name="sha1curva"
                  value={equipments.sha1curva ? equipments.sha1curva : ''}
                  variant="outlined"
                  disabled={true}
                />

                <TextField
                  className={classes.textField}
                  aria-readonly="true"
                  fullWidth
                  label="Data de calibração"
                  name="sha1curva"
                  value={equipments.dcal ? equipments.dcal : ''}
                  variant="outlined"
                  disabled={true}
                />

                <TextField
                  className={classes.textField}
                  aria-readonly="true"
                  label="Curva Principal"
                  name="curp"
                  value={equipments.charts ? equipments.charts.principal : ''}
                  variant="outlined"
                  disabled={true}
                />

                <TextField
                  className={classes.textField}
                  label="Curva Complementar"
                  name="curc"
                  value={
                    equipments.charts ? equipments.charts.complementar : ''
                  }
                  variant="outlined"
                  disabled={true}
                />

                <TextField
                  className={classes.textField}
                  label="Versão do Software"
                  name="versaosw"
                  value={equipments.versaosw ? equipments.versaosw : ''}
                  variant="outlined"
                  disabled={true}
                />

                <TextField
                  className={classes.textField}
                  autoComplete="off"
                  label="ID do copo"
                  name="cupid"
                  value={equipments.cupid ? equipments.cupid : ''}
                  variant="outlined"
                  disabled={true}
                />

                <TextField
                  className={classes.textField}
                  label="Versão do Firmware Principal"
                  name="versaofwpri"
                  value={equipments.versaofwpri ? equipments.versaofwpri : ''}
                  variant="outlined"
                  disabled={true}
                />

                <TextField
                  className={classes.textField}
                  aria-readonly="true"
                  label="Versão do Firmware Osciladora"
                  name="versaofwosc"
                  value={equipments.versaofwosc ? equipments.versaofwosc : ''}
                  variant="outlined"
                  disabled={true}
                />

                {equipments.copo ?
                  <>
                    <TextField
                      className={classes.textField}
                      aria-readonly="true"
                      label="Tamanho do Copo"
                      name="tamanhocopo"
                      value={
                        Number(equipments.copo) === 0 ? "Sem dados" :
                          Number(equipments.copo) === 150 ? `Pequeno | ${Number(equipments.copo)}` :
                            Number(equipments.copo) > 150 ? `Pequeno | ${Number(equipments.copo)}` :
                              Number(equipments.copo) < 150 ? `Grande | ${Number(equipments.copo)}` : ""}
                      variant="outlined"
                      disabled={true}
                    />

                    <TextField
                      className={classes.textField}
                      aria-readonly="true"
                      label="Versão do Termistor"
                      name="copo"
                      value={
                        Number(equipments.copo) === 0 ? "Sem dados" :
                          Number(equipments.copo) === 150 ? "Rev 0" :
                            Number(equipments.copo) > 150 ? "Rev 1" :
                              Number(equipments.copo) < 150 ? "Rev 0" : ""}
                      variant="outlined"
                      disabled={true}
                    />
                  </>
                  : null
                }

                <div style={{ display: 'flex', width: '100%', textAlign: 'center' }}>
                  <div>
                    <Checkbox
                      checked={equipments.wf === 'OK'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      disabled={permission === true ? false : true}
                    />
                    <Typography variant="inherit">Wifi</Typography>
                  </div>
                  <div>
                    <Checkbox
                      checked={equipments.calph === 'OK'}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      disabled={permission === true ? false : true}
                    />
                    <Typography variant="inherit">PH Calibrado</Typography>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center | left'
                  }}>

                  <TextField
                    className={classes.textField}
                    autoComplete="off"
                    label="Dias para Ativação"
                    name="daystoactivate"
                    onChange={(event) => {
                      setEnable(true)
                      fieldChange(event, 'daystoactivate', event.target.value)
                    }}
                    value={equipments.daystoactivate ? equipments.daystoactivate : ''}
                    variant="outlined"
                  />

                  {enable ?
                    <Button
                      disabled={permission === true ? false : true}
                      className={classes.saveButton}
                      color="primary"
                      onClick={() => onSubmit()}
                      variant="contained">
                      Atualizar
                    </Button>
                    : null
                  }
                </div>

                <div>
                  <Checkbox
                    checked={equipments.mode === 'RENTAL'}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    disabled={permission === true ? false : true}
                  />
                  <Typography variant="inherit">Locação</Typography>
                </div>

                <div>
                  <Checkbox
                    checked={equipments.isentkey}
                    onChange={(event) =>
                      checkboxIsentChange(event.target.checked)
                    }
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    disabled={permission === true ? false : true}
                  />
                  <Typography variant="inherit">Isenção de taxas</Typography>
                </div>

                <div className='mt-4'>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Escolha um plano</FormLabel>
                    <RadioGroup
                      aria-label="plan"
                      name="plan"
                      value={equipments.plan || ""}
                      onChange={handlePlanChange}
                      row // Adicionado para alinhar horizontalmente
                    >
                      {equipments.mode === "RENTAL" ?
                        <>
                          <FormControlLabel value="NORMAL" control={<Radio color="primary" />} label="NORMAL" />
                          <FormControlLabel value="FOB" control={<Radio color="primary" />} label="FOB" />
                        </>
                        :
                        <>
                          <FormControlLabel value="NORMAL" control={<Radio color="primary" />} label="NORMAL" />
                          <FormControlLabel value="PLUS" control={<Radio color="primary" />} label="PLUS" />
                          <FormControlLabel value="FLEX" control={<Radio color="primary" />} label="FLEX" />
                          <FormControlLabel value="PREMIUM" control={<Radio color="primary" />} label="PREMIUM" />
                        </>
                      }
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </Grid>
          </Grid>
        </form>
      </CardContent>
      <Divider />
    </Card>
  );
};

Form.propTypes = {
  alertMessage: PropTypes.object,
  className: PropTypes.string,
  equipments: PropTypes.object,
  fieldChange: PropTypes.object,
  onSubmit: PropTypes.func,
  unities: PropTypes.array
};

export default Form;
