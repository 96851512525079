import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography, colors } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import Modal from 'react-bootstrap/Modal';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 16px',
    borderRadius: theme.shape.borderRadius
  },
  default: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  success: {
    backgroundColor: colors.green[600],
    color: theme.palette.white
  },
  info: {
    backgroundColor: colors.lightBlue[600],
    color: theme.palette.white
  },
  warning: {
    backgroundColor: colors.orange[900],
    color: theme.palette.white
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  message: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0'
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing(1)
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    paddingLeft: 16,
    marginRight: -8
  }
}));

const icons = {
  default: <InfoIcon />,
  success: <CheckCircleIcon />,
  info: <InfoIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />
};

const Alert = forwardRef((props, ref) => {
  const { className, icon, variant, message, ...rest } = props;
  const classes = useStyles();
  const [show, setShow] = useState(true);
  const [modalShow, setModalShow] = useState(true);

  useEffect(() => {
    setTimeout(() => { setShow(false) }, 2000);
  }, []);

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ "borderRadius": 8 }}
      >
        <Paper
          {...rest}
          className={clsx(classes.root, classes[variant], className)}
          component={Typography}
          ref={ref}
          variant="h5"
          style={{ "margin": 0, "padding": 20, "borderRadius": 8 }}
        >
          <span className={classes.icon}>{icon || icons[variant]}</span>
          <div className={classes.message}>{message}</div>
        </Paper>
      </Modal>
    );
  }
  return (
    <div>
      {show ?
        <div style={{ "display": "none" }}>
          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </div>
        : null}
    </div>
  );
});

Alert.displayName = 'Alert';

Alert.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'info', 'success', 'warning', 'error'])
};

Alert.defaultProps = {
  variant: 'default'
};

export default Alert;
