import React, { useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography, TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { IoSearch } from "react-icons/io5";

const useStyles = makeStyles((theme) => ({
  root: {},
  h3: {
    paddingTop: '10px',
    paddingLeft: '10px',
    color: 'white'
  },
  checkbox: {
    color: '#FFFFFF !important'
  },
  label: {
    color: '#FFFFFF',
    fontSize: '14px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 310,
    background: 'white'
  },
  input: {
    color: 'white',
    width: '100px',
    borderBottom: '1px solid white',
    marginTop: theme.spacing(1),
    '& .MuiInputBase-input': {
      color: 'white',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
  },
}));

const Filter = (props) => {
  const classes = useStyles();
  const { filter, companyNames, count } = props;

  const [checkBox, setCheckBox] = useState([true, false, false, false, false, false, false, false, false]);
  const [company, setCompany] = useState('');
  const [serialNumber, setSerialNumber] = useState('');

  const handleCheckBoxChange = (position) => {
    let checkVals = [...checkBox];
    checkVals[0] = false;
    checkVals[position] = !checkVals[position];

    if (checkVals.every(val => val === false)) {
      checkVals[0] = true;
    }

    if (position === 0) {
      checkVals.fill(false);
      checkVals[0] = true;
    } else if (position === 7) {
      checkVals[8] = false;
    } else if (position === 8) {
      checkVals[7] = false;
    }

    setCheckBox(checkVals);
    filter(checkVals, company, serialNumber);
  };

  const handleChange = (event) => {
    setCompany(event.target.value);
    filter(checkBox, event.target.value, serialNumber);
  };

  const handleSerialNumberChange = (event) => {
    setSerialNumber(event.target.value.toUpperCase());
    filter(checkBox, company, event.target.value);
  };

  return (
    <div style={{ height: '5vh' }}>
      <FormControlLabel
        control={
          <Checkbox
            className={classes.checkbox}
            style={{ marginLeft: '10px' }}
            checked={checkBox[0]}
            name="checkedB"
            onChange={() => handleCheckBoxChange(0)}
          />
        }
        label={<label className={classes.label}>Todos</label>}
      />
      <FormControlLabel
        control={
          <Checkbox
            className={classes.checkbox}
            checked={checkBox[1]}
            name="checkedB"
            onChange={() => handleCheckBoxChange(1)}
          />
        }
        label={<label className={classes.label}>Locação</label>}
      />
      <FormControlLabel
        control={
          <Checkbox
            className={classes.checkbox}
            checked={checkBox[2]}
            name="checkedB"
            onChange={() => handleCheckBoxChange(2)}
          />
        }
        label={<label className={classes.label}>SIMT</label>}
      />
      <FormControlLabel
        control={
          <Checkbox
            className={classes.checkbox}
            checked={checkBox[3]}
            name="checkedB"
            onChange={() => handleCheckBoxChange(3)}
          />
        }
        label={<label className={classes.label}>BIMT</label>}
      />
      <FormControlLabel
        control={
          <Checkbox
            className={classes.checkbox}
            checked={checkBox[4]}
            name="checkedB"
            onChange={() => handleCheckBoxChange(4)}
          />
        }
        label={<label className={classes.label}>CPMT</label>}
      />
      <FormControlLabel
        control={
          <Checkbox
            className={classes.checkbox}
            checked={checkBox[5]}
            name="checkedB"
            onChange={() => handleCheckBoxChange(5)}
          />
        }
        label={<label className={classes.label}>EEMT</label>}
      />
      <FormControlLabel
        control={
          <Checkbox
            className={classes.checkbox}
            checked={checkBox[6]}
            name="checkedB"
            onChange={() => handleCheckBoxChange(6)}
          />
        }
        label={<label className={classes.label}>ESMR</label>}
      />
      <FormControlLabel
        control={
          <Checkbox
            className={classes.checkbox}
            checked={checkBox[7]}
            name="checkedB"
            onChange={() => handleCheckBoxChange(7)}
          />
        }
        label={<label className={classes.label}>ESTADOS</label>}
      />
      <FormControlLabel
        control={
          <Checkbox
            className={classes.checkbox}
            checked={checkBox[8]}
            name="checkedB"
            onChange={() => handleCheckBoxChange(8)}
          />
        }
        label={<label className={classes.label}>CIDADES</label>}
      />

      <TextField
        style={{ marginTop: 6 }}
        className={classes.input}
        value={serialNumber}
        onChange={handleSerialNumberChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {!serialNumber && <IoSearch color='white' size={20} />}
            </InputAdornment>
          ),
        }}
      />

      <Typography
        variant={'inherit'}
        style={{ color: 'white', marginLeft: '10px' }}
      >
        EMPRESA
      </Typography>
      <FormControl className={classes.formControl}>
        <Select
          labelId="select-label"
          id="select"
          value={company}
          onChange={handleChange}
          style={{ paddingLeft: '10px' }}
        >
          <MenuItem value="">
            <em>Cooperativas</em>
          </MenuItem>
          {companyNames && companyNames.map((comp) => (
            <MenuItem key={comp} value={comp}>{comp}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography
        variant={'inherit'}
        style={{ color: 'white', marginLeft: '10px', marginTop: '10px' }}
      >
        TOTAL: {count ? count : 0}
      </Typography>

    </div>
  );
};

export default Filter;
