import React, { useState } from 'react';

import clsx from 'clsx';
import axios from 'utils/axios';
import PropTypes from 'prop-types';
import { getToken } from 'actions/sessionActions';

import { Alert } from 'components';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Button,
  Divider,
  TextField,
  colors
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  alert: {
    marginBottom: '30px;'
  }
}));

const Security = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    password: '',
    confirm: ''
  });

  const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const valid = values.password && values.password === values.confirm;

  const handleChangePassword = async () => {
    try {
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).put('/change_password', {
        newPassword: values.password
      });
      setAlertMessage({ message: 'Atualizado com suceso!', type: 'success' });
    } catch (e) {
      setAlertMessage({ message: 'Falha ao atualizar!', type: 'error' });
    }

    setValues({ password: '', confirm: '' });
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Alterar Senha" />
      <Divider />
      <CardContent>
        <form>
          {alertMessage.message && (
            <Alert
              className={classes.alert}
              message={alertMessage.message}
              variant={alertMessage.type}
            />
          )}
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              sm={6}
              xs={12}
            >
              <TextField
                autoComplete="off"
                fullWidth
                label="Nova Senha"
                name="password"
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <TextField
                autoComplete="off"
                fullWidth
                label="Confirmar Nova Senha"
                name="confirm"
                onChange={handleChange}
                type="password"
                value={values.confirm}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          className={classes.saveButton}
          disabled={!valid}
          onClick={handleChangePassword}
          variant="contained"
        >
          Salvar
        </Button>
      </CardActions>
    </Card>
  );
};

Security.propTypes = {
  className: PropTypes.string
};

export default Security;
