import React, { useState } from 'react';

import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';

import { Page } from 'components';
import { Header, Form } from './components';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3, 3, 6, 3)
  },
  aboutAuthor: {
    marginTop: theme.spacing(3)
  },
  aboutProject: {
    marginTop: theme.spacing(3)
  },
  projectCover: {
    marginTop: theme.spacing(3)
  },
  projectDetails: {
    marginTop: theme.spacing(3)
  },
  preferences: {
    marginTop: theme.spacing(3)
  },
  actions: {
    marginTop: theme.spacing(3)
  }
}));

const UsersAdd = () => {
  const classes = useStyles();

  const [user, setUser] = useState({
    name: '',
    email: '',
    fone: '',
    password: ''
  });

  const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });

  const handleFieldChange = (event, field, value) => {
    event.persist && event.persist();
    setUser(values => ({
      ...values,
      [field]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post('/register', {
        name: user.name,
        email: user.email,
        fone: user.fone,
        password: user.password
      });
      setAlertMessage({ message: 'Adicionado com sucesso!', type: 'success' });
      setUser({
        name: '',
        email: '',
        username: '',
        password: '',
      });
    } catch (e) {
      setAlertMessage({ message: 'Falha ao adicionar!', type: 'error' });
    }
  };

  return (
    <Page
      className={classes.root}
      title="Usuários"
    >
      <Header />
      <Form
        alertMessage={alertMessage}
        className={classes.aboutProject}
        fieldChange={handleFieldChange}
        onSubmit={handleSubmit}
        user={user}
      />
    </Page>
  );
};

export default UsersAdd;
