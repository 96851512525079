import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Alert } from 'components';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Grid,
  Divider,
  Button
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '30px'
  },
  alert: {
    marginBottom: theme.spacing(3)
  },
  formGroup: {
    marginBottom: theme.spacing(3)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  fieldHint: {
    margin: theme.spacing(1, 0)
  },
  tags: {
    marginTop: theme.spacing(1),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  dateField: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const Form = props => {
  const {
    className,
    alertMessage,
    equipments,
    fieldChange,
    onSubmit,
    unities,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <form>
          {alertMessage.message && (
            <Alert
              className={classes.alert}
              message={alertMessage.message}
              variant={alertMessage.type}
            />
          )}
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <div className={classes.formGroup}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  label="Nome do Equipamento"
                  name="name"
                  onChange={event =>
                    fieldChange(event, 'name', event.target.value)
                  }
                  value={equipments.name}
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <div className={classes.formGroup}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  label="Modelo"
                  name="model"
                  onChange={event =>
                    fieldChange(event, 'model', event.target.value)
                  }
                  value={equipments.model}
                  variant="outlined"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <div className={classes.formGroup}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  label="Número de Série"
                  name="serialnumber"
                  onChange={event =>
                    fieldChange(event, 'serialnumber', event.target.value)
                  }
                  value={equipments.serialnumber}
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <div className={classes.formGroup}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  label="IP"
                  name="ip"
                  onChange={event =>
                    fieldChange(event, 'ip', event.target.value)
                  }
                  value={equipments.ip}
                  variant="outlined"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <div className={classes.formGroup}>
                <TextField
                  fullWidth
                  label="Status"
                  name="status"
                  onChange={event =>
                    fieldChange(event, 'status', event.target.value)
                  }
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={equipments.status}
                  variant="outlined">
                  <option />
                  <option key="1" value="true">
                    Online
                  </option>
                  <option key="2" value="false">
                    Offline
                  </option>
                </TextField>
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <div className={classes.formGroup}>
                <TextField
                  fullWidth
                  label="Disponibilidade"
                  name="active"
                  onChange={event =>
                    fieldChange(event, 'active', event.target.value)
                  }
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={equipments.active}
                  variant="outlined">
                  <option />
                  <option key="1" value="true">
                    Ativado
                  </option>
                  <option key="2" value="false">
                    Desativado
                  </option>
                </TextField>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <div className={classes.formGroup}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  label="Posição"
                  name="position"
                  onChange={event =>
                    fieldChange(event, 'position', event.target.value)
                  }
                  value={equipments.position}
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <div className={classes.formGroup}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  label="Grão"
                  name="grain"
                  onChange={event =>
                    fieldChange(event, 'grain', event.target.value)
                  }
                  value={equipments.grain}
                  variant="outlined"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <div className={classes.formGroup}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  label="Alcance Mínimo"
                  name="idealRangeMin"
                  onChange={event =>
                    fieldChange(event, 'idealRangeMin', event.target.value)
                  }
                  step="0.01"
                  type="number"
                  value={equipments.idealRangeMin}
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <div className={classes.formGroup}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  label="Alcance Máximo"
                  name="idealRangeMax"
                  onChange={event =>
                    fieldChange(event, 'idealRangeMax', event.target.value)
                  }
                  step="0.01"
                  type="number"
                  value={equipments.idealRangeMax}
                  variant="outlined"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <div className={classes.formGroup}>
                <TextField
                  fullWidth
                  label="Empresa"
                  name="unity_id"
                  onChange={event =>
                    fieldChange(event, 'unity_id', event.target.value)
                  }
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={equipments.unity_id}
                  variant="outlined">
                  <option />

                  {unities &&
                    unities.map(unity => (
                      <option key={unity.id} value={unity.id}>
                        {unity.name}
                      </option>
                    ))}
                </TextField>
              </div>
            </Grid>
          </Grid>
        </form>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          className={classes.saveButton}
          color="primary"
          onClick={() => onSubmit()}
          variant="contained">
          Salvar
        </Button>
        <Button
          color="secondary"
          component={RouterLink}
          onClick={() => onSubmit()}
          to={'/equipments'}
          variant="contained">
          Cancelar
        </Button>
      </CardActions>
    </Card>
  );
};

Form.propTypes = {
  alertMessage: PropTypes.object,
  className: PropTypes.string,
  equipments: PropTypes.object,
  fieldChange: PropTypes.object,
  onSubmit: PropTypes.func,
  unities: PropTypes.array
};

export default Form;
