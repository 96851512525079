import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page, NotifyModal } from 'components';
import { Header } from './components';
import FileUpload from 'components/FileUpload';
import Alert from 'react-bootstrap/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  },
  alert: {
    marginTop: '20px'
  }
}));

const Charts = () => {
  const classes = useStyles();

  const user = JSON.parse(localStorage.getItem('userData'))
  if (user && user.permission.charts.read === false) {
    window.location.replace('/home')
  }

  const [permission, setPermission] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ "message": '', "type": '' });

  //--------------------------------------------------------------
  const [type, setType] = useState({ "color": "", "status": "" });
  const [modalShow, setModalShow] = useState(false);

  const handleAlertModalSuccess = (msg) => {
    setType({ "color": "#198754", "status": msg })
    setModalShow(true)
  }

  const handleAlertModalError = (msg) => {
    setType({ "color": "red", "status": msg })
    setModalShow(true)
  }
  //--------------------------------------------------------------

  useEffect(() => {
    const { permission } = JSON.parse(localStorage.getItem('userData'))
    setPermission(permission.units.write)
  }, [])


  return (
    <Page className={classes.root} title="Curvas">
      <Header />

      {alertMessage.message && (
        <Alert
          className={classes.alert}
          message={alertMessage.message}
          variant={alertMessage.type}
        />
      )}

      <NotifyModal
        type={type}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <hr />

      <Alert variant="warning" className='mt-4 mb-4 p-2'>
        <Alert.Heading>Atenção!</Alert.Heading>
        <p>
          Ao enviar a nova curva, a curva atual será substituída definitivamente! Certifique-se de que os dados estão corretos antes de prosseguir.
        </p>
        <hr />
        <p className="mb-0">
          Todos os equipamentos com conexão à internet serão atualizados automaticamente.
        </p>
      </Alert>

      <hr className='mt-4 mb-4' />

      {/* Type = CUSTOM, INMETRO, COMPLEMENT */}
      <>
        <h4>Curva Inmetro</h4>
        <FileUpload
          msg={"Enviar a nova curva substituirá a curva INMETRO atual!"}
          type={"INMETRO"}
          permission={permission}
          setAlertMessage={setAlertMessage}
          handleAlertModalError={handleAlertModalError}
          handleAlertModalSuccess={handleAlertModalSuccess}
        />
      </>

      <hr className='mt-4 mb-4' />

      <>
        <h4>Curva Complementar</h4>
        <FileUpload
          msg={"Enviar a nova curva substituirá a curva COMPLEMENTAR atual!"}
          type={"COMPLEMENT"}
          permission={permission}
          setAlertMessage={setAlertMessage}
          handleAlertModalError={handleAlertModalError}
          handleAlertModalSuccess={handleAlertModalSuccess}
        />
      </>
    </Page>
  );
};

export default Charts;
