import React, { useEffect } from 'react';
import Close from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const InfoWindow = (props) => {
  const { close, equipment, setCenter, located } = props;

  const styles = {
    container: {
      position: 'relative',
      bottom: 145,
      left: '-100px',
      width: 250,
      backgroundColor: 'white',
      boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
      padding: 10,
      fontSize: 14,
      borderRadius: 5,
      zIndex: 100,
      marginBottom: 5,
    },
    closeIcon: {
      position: 'absolute',
      top: 5,
      right: 5,
      cursor: 'pointer',
    },
    marginBottom: {
      marginBottom: 5,
    },
  };

  useEffect(() => {
    setCenter({
      lat: equipment.lat,
      lng: equipment.lng
    });
  }, [setCenter, equipment.lat, equipment.lng]);

  return (
    <div style={styles.container}>
      <Close onClick={close} style={styles.closeIcon} />

      <div>
        {equipment.serial && <Typography variant="h6">{equipment.serial}</Typography>}
        {located && <Typography variant="body2">Equipamento: Locado</Typography>}
        <Typography variant="overline">Última ativação:</Typography>
        <Typography variant="body2">{equipment.date || "N/A"}</Typography>
        <Typography variant="body2">
          Versão firmware osciladora: {equipment.versaofwosc || "N/A"}
        </Typography>
        <Typography variant="body2">
          Versão firmware principal: {equipment.versaofwpri || "N/A"}
        </Typography>
        <Typography style={styles.marginBottom} variant="body2">
          Versão software: {equipment.versaosw || "N/A"}
        </Typography>
        {equipment.serial && (
          <Link to={`/equipments/${equipment.serial}`}>ANÁLISE</Link>
        )}
      </div>
    </div>
  );
};
export default InfoWindow;
