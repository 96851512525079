import React, { useState, useEffect } from 'react';
import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';
import { Link as RouterLink } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Page } from 'components';
import { Header } from './components';

import { Divider, colors } from '@material-ui/core';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import GroupIcon from '@material-ui/icons/Group';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import StorageIcon from '@material-ui/icons/Storage';
import EquipmentIcon from '@material-ui/icons/Explicit';

import { makeStyles } from '@material-ui/styles';

import { HorizontalBar } from 'react-chartjs-2';

import Grid from '@material-ui/core/Grid';
import CertificadoDash from './components/CertificadoDash';

import ApiMServer from 'services/ApiMServer';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(1)
  },
  grid: {
    flexGrow: 1
  },
  control: {
    padding: theme.spacing(2)
  },
  card: {
    margin: theme.spacing(2)
  },
  center: {
    textAlign: 'center'
  },
  media: {
    textAlign: 'center',
    height: 50,
    width: 50
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(1)
  },
  indicator: {
    backgroundColor: colors.green[500]
  }
}));

const options = {
  "scales": {
    "y": [{
      ticks: {
        beginAtZero: true
      },
      maxBarThickness: 30
    }],
    "x": [{
      ticks: {
        beginAtZero: true
      }
    }]
  }
};

const Home = () => {
  const user = JSON.parse(localStorage.getItem('userData'))
  if (user && user.permission.home.read === false) {
    let keys = Object.keys(user.permission)
    for (let i = 0; i < keys.length; i++) {
      let page = keys[i];
      if (user.permission[page].read) {
        window.location.replace('/' + page)
        break;
      }
    }
  }

  const classes = useStyles();

  const [n_users, setNusers] = useState('---');
  const [n_measurements, setNMeasurements] = useState('---');
  const [n_unblocks, setNUnblocks] = useState('---');
  let [loading, setLoading] = useState(false);

  const [equipmentMetrics, setEquipmentMetrics] = useState({
    "simt": { n: 0, softVesions: {}, labels: [], data: [] },
    "bimt": { n: 0, softVesions: {}, labels: [], data: [] },
    "cpmt": { n: 0, softVesions: {}, labels: [], data: [] },
    "eemt": { n: 0, softVesions: {}, labels: [], data: [] },
    "esmr": { n: 0, softVesions: {}, labels: [], data: [] }
  });


  useEffect(() => {
    const handleGetUser = async () => {
      const storedUser = localStorage.getItem('userData');
      if (!storedUser) return;

      try {
        const { _id } = JSON.parse(storedUser);
        const { data: newUserData } = await ApiMServer.get(`/users/${_id}`);

        if (newUserData) {
          const updatedUser = {
            ...JSON.parse(storedUser),
            ...newUserData,
          };
          localStorage.setItem('userData', JSON.stringify(updatedUser));
        }
      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);
      }
    };
    handleGetUser()

    const getAll = async () => {
      setLoading(true)
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken())
        .get('/dashboard')
        .then((response) => {
          const data = response.data;
          setNusers(data.n_users);
          setNMeasurements(data.n_measurements);
        });

      await axios(process.env.REACT_APP_API_GLOBAL_SERVER, getToken())
        .get('/equipment/metrics')
        .then((response) => {
          const data = response.data;

          const sortVersionsDescending = (versions) => {
            return Object.entries(versions)
              .sort((a, b) => b[1] - a[1])
              .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
              }, {});
          };

          const sortedSimt = sortVersionsDescending(data.simt.softVesions);
          data.simt.labels = Object.keys(sortedSimt);
          data.simt.data = Object.values(sortedSimt);

          const sortedBimt = sortVersionsDescending(data.bimt.softVesions);
          data.bimt.labels = Object.keys(sortedBimt);
          data.bimt.data = Object.values(sortedBimt);

          const sortedCpmt = sortVersionsDescending(data.cpmt.softVesions);
          data.cpmt.labels = Object.keys(sortedCpmt);
          data.cpmt.data = Object.values(sortedCpmt);

          const sortedEemt = sortVersionsDescending(data.eemt.softVesions);
          data.eemt.labels = Object.keys(sortedEemt);
          data.eemt.data = Object.values(sortedEemt);

          const sortedEsmr = sortVersionsDescending(data.esmr.softVesions);
          data.esmr.labels = Object.keys(sortedEsmr);
          data.esmr.data = Object.values(sortedEsmr);

          setEquipmentMetrics(data);
          localStorage.setItem('equipMetrics', JSON.stringify(data));
          setLoading(false);
        });

      await axios(process.env.REACT_APP_API_GERA_SENHA, getToken())
        .get('/log/unlockcount')
        .then((response) => {
          const data = response.data;
          setNUnblocks(data.count);
        });
    }
    getAll()
  }, []);

  const handlePlanilha = async (serial) => {
    try {
      window.open(`${process.env.REACT_APP_API_MOTOMCO}/dashboard/activelogsfilter?typeSerial=${serial}`, "_blank")
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {user.permission.home.read ?
        <Page className={classes.root} title="Home">
          <Header />
          <Divider className={classes.divider} />
          <div className={classes.content}>
            <Grid className={classes.grid} container spacing={2}>
              <Grid item xs={12}>
                <Grid item>
                  <Card className={classes.card}>
                    <CardContent>
                      <div className={classes.center}>
                        <EquipmentIcon className={classes.media} />

                        {loading ? (
                          <div style={{ textAlign: 'center', marginTop: 30 }}>
                            <CircularProgress />
                          </div>
                        ) : null}

                        <Typography component="h2" gutterBottom variant="h1">
                          {equipmentMetrics.count}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2">
                          Equipamentos
                        </Typography>
                      </div>

                      <Grid container justifyContent="center">
                        <Grid item xs={6}>
                          <div style={{ textAlign: 'center' }}>
                            <Typography className='mt-3' variant={'h4'}>SIMT</Typography>
                            <Typography>{equipmentMetrics.simt.n}</Typography>
                            <Button
                              color="default"
                              component={RouterLink}
                              size="small"
                              //to={'/equipments/model/simt'}
                              onClick={() => { handlePlanilha("SIMT") }}
                              disabled={user.permission.home.write === true ? false : true}
                              variant="outlined">
                              Relatório
                            </Button>
                            <HorizontalBar
                              data={{
                                labels: equipmentMetrics.simt.labels,
                                datasets: [
                                  {
                                    label: 'Equipamentos',
                                    data: equipmentMetrics.simt.data,
                                    backgroundColor: 'rgba(18, 88, 25, 0.8)',
                                    borderColor: 'black',
                                    borderWidth: 1
                                  }
                                ]
                              }}
                              options={options}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={6}>
                          <div style={{ textAlign: 'center' }}>
                            <Typography className='mt-3' variant={'h4'}>BIMT</Typography>
                            <Typography>{equipmentMetrics.bimt.n}</Typography>
                            <Button
                              color="default"
                              component={RouterLink}
                              size="small"
                              //to={'/equipments/model/bimt'}
                              onClick={() => { handlePlanilha("BIMT") }}
                              disabled={user.permission.home.write === true ? false : true}
                              variant="outlined">
                              Relatório
                            </Button>
                            <HorizontalBar
                              data={{
                                labels: equipmentMetrics.bimt.labels,
                                datasets: [
                                  {
                                    label: 'Equipamentos',
                                    data: equipmentMetrics.bimt.data,
                                    backgroundColor: 'rgba(18, 88, 25, 0.8)',
                                    borderColor: 'black',
                                    borderWidth: 1
                                  }
                                ]
                              }}
                              options={options}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container justifyContent="center">
                        <Grid item xs={6}>
                          <div style={{ textAlign: 'center' }}>
                            <Typography className='mt-3' variant={'h4'}>CPMT</Typography>
                            <Typography>{equipmentMetrics.cpmt.n}</Typography>
                            <Button
                              color="default"
                              component={RouterLink}
                              size="small"
                              //to={'/equipments/model/cpmt'}
                              onClick={() => { handlePlanilha("CPMT") }}
                              disabled={user.permission.home.write === true ? false : true}
                              variant="outlined">
                              Relatório
                            </Button>
                            <HorizontalBar
                              data={{
                                labels: equipmentMetrics.cpmt.labels,
                                datasets: [
                                  {
                                    label: 'Equipamentos',
                                    data: equipmentMetrics.cpmt.data,
                                    backgroundColor: 'rgba(18, 88, 25, 0.8)',
                                    borderColor: 'black',
                                    borderWidth: 1
                                  }
                                ]
                              }}
                              options={options}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={6}>
                          <div style={{ textAlign: 'center' }}>
                            <Typography className='mt-3' variant={'h4'}>EEMT</Typography>
                            <Typography>{equipmentMetrics.eemt.n}</Typography>
                            <Button
                              color="default"
                              component={RouterLink}
                              size="small"
                              //to={'/equipments/model/eemt'}
                              onClick={() => { handlePlanilha("EEMT") }}
                              disabled={user.permission.home.write === true ? false : true}
                              variant="outlined">
                              Relatório
                            </Button>
                            <HorizontalBar
                              data={{
                                labels: equipmentMetrics.eemt.labels,
                                datasets: [
                                  {
                                    label: 'Equipamentos',
                                    data: equipmentMetrics.eemt.data,
                                    backgroundColor: 'rgba(18, 88, 25, 0.8)',
                                    borderColor: 'black',
                                    borderWidth: 1
                                  }
                                ]
                              }}
                              options={options}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container justifyContent="center" spacing={50}>
                        <Grid item xs={6}>
                          <div style={{ textAlign: 'center' }}>
                            <Typography className='mt-3' variant={'h4'}>ESMR</Typography>
                            <Typography>{equipmentMetrics.esmr.n}</Typography>
                            <Button
                              color="default"
                              component={RouterLink}
                              size="small"
                              //to={'/equipments/model/esmr'}
                              onClick={() => { handlePlanilha("ESMR") }}
                              disabled={user.permission.home.write === true ? false : true}
                              variant="outlined">
                              Relatório
                            </Button>
                            <HorizontalBar
                              data={{
                                labels: equipmentMetrics.esmr.labels,
                                datasets: [
                                  {
                                    label: 'Equipamentos',
                                    data: equipmentMetrics.esmr.data,
                                    backgroundColor: 'rgba(18, 88, 25, 0.8)',
                                    borderColor: 'black',
                                    borderWidth: 1
                                  }
                                ]
                              }}
                              options={options}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={6}>
                          <div style={{ textAlign: 'center' }}></div>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Button color="primary" size="small" href="/equipments">
                        Visualizar
                      </Button>

                      <Button
                        color="primary"
                        size="small"
                        onClick={() => { window.open(process.env.REACT_APP_API_GLOBAL_SERVER + "/log", "_blank") }}
                      >
                        Download Relatório
                      </Button>

                    </CardActions>
                  </Card>
                </Grid>

                <Grid item>
                  <Card className={classes.card}>
                    <CardContent>
                      <div className={classes.center}>
                        <CertificadoDash />
                      </div>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item>
                  <Card className={classes.card}>
                    <CardContent>
                      <div className={classes.center}>
                        <GroupIcon className={classes.media} />
                        <Typography component="h2" gutterBottom variant="h1">
                          {n_users}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2">
                          Número de usuários cadastrados
                        </Typography>
                      </div>
                    </CardContent>
                    <CardActions>
                      <Button color="primary" href="/users" size="small">
                        Visualizar
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>

                <Grid item>
                  <Card className={classes.card}>
                    <CardContent>
                      <div className={classes.center}>
                        <StorageIcon className={classes.media} />
                        <Typography component="h2" gutterBottom variant="h1">
                          {n_measurements}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2">
                          Medições realizadas
                        </Typography>
                      </div>
                    </CardContent>
                    <CardActions>
                      <Button color="primary" size="small"></Button>
                    </CardActions>
                  </Card>
                </Grid>

                <Grid item>
                  <Card className={classes.card}>
                    <CardContent>
                      <div className={classes.center}>
                        <LockOpenIcon className={classes.media} />
                        <Typography component="h2" gutterBottom variant="h1">
                          {n_unblocks}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2">
                          Ativações de equipamento
                        </Typography>
                      </div>
                    </CardContent>
                    <CardActions>
                      <Button color="primary" size="small"></Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Page>
        : <h2 className='mt-3 text-center'> <br /> {' ... '} </h2>
      }
    </>
  )
}

export default Home;
