import React, { useState, useRef, useEffect } from 'react';

// eslint-disable-next-line react/prop-types
const Terminal = ({ onCommandSubmit, response, connectionStabished, user }) => {
  const [commands, setCommands] = useState([]);
  const [input, setInput] = useState('');
  const terminalRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      const command = `> ${input}`;
      // Adiciona o comando do usuário
      setCommands((prevCommands) => [...prevCommands, { text: command, type: 'command' }]);
      setInput('');
      // Chama a função passada via props
      if (onCommandSubmit) {
        onCommandSubmit(input);
      }
    }
  };

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [commands]);

  useEffect(() => {
    if (response) {
      // eslint-disable-next-line react/prop-types
      const responseType = response.startsWith('comando:') ? 'highlightedResponse' : 'normalResponse';
      setCommands((prevCommands) => [...prevCommands, { text: response, type: responseType }]);
    }
  }, [response]);

  return (
    <div style={styles.terminalContainer}>
      <div
        ref={terminalRef}
        style={styles.output}
      >
        {commands.map((command, index) => (
          <div
            key={index}
            style={
              command.type === 'command'
                ? styles.command
                : command.type === 'highlightedResponse'
                  ? styles.highlightedResponse
                  : styles.normalResponse
            }
          >
            {command.text}
          </div>
        ))}
      </div>
      <form
        onSubmit={handleSubmit}
        style={styles.form}
      >
        <input
          onChange={(e) => setInput(e.target.value)}
          placeholder="Digite um comando..."
          style={styles.input}
          type="text"
          value={input}
          disabled={user.master ? !user.master : true}
        />
      </form>
    </div>
  );
};

const styles = {
  terminalContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: '#1e1e1e',
    color: '#00ff00',
    borderRadius: '5px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  output: {
    flex: 1,
    overflowY: 'auto',
    marginBottom: '10px',
    whiteSpace: 'pre-wrap', // Faz com que \n quebre a linha
  },
  command: {
    color: '#00ff00',
    margin: '2px 0',
  },
  highlightedResponse: {
    color: '#1E90FF',
    margin: '2px 0',
  },
  normalResponse: {
    color: '#FFFFFF',
    margin: '2px 0',
  },
  form: {
    display: 'flex',
  },
  input: {
    flex: 1,
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #555',
    backgroundColor: '#333',
    color: '#00ff00',
    outline: 'none',
  },
};

export default Terminal;
