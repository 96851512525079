import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  colors
} from '@material-ui/core';

import getInitials from 'utils/getInitials';
import ApiMServer from 'services/ApiMServer';
import dateFormat from 'dateformat';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  ql: {
    marginLeft: '10px'
  },
  rmvButton: {
    borderColor: '#FE2020',
    color: '#FE2020',
    marginLeft: '10px',
    background: 'transparent'
  },
  analyticsButton: {
    borderColor: colors.orange[700],
    color: colors.orange[700],
    marginRight: '10px',
    background: 'transparent'
  },
  online: {
    color: colors.green[700]
  },
  offline: {
    color: colors.red[600]
  }
}));

const Cooperatives = (props) => {
  const { className, unitsCompany, onRemoveButton, data, permission, ...rest } = props;
  const classes = useStyles();
  const [selectedData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [units, setUnits] = useState();

  useEffect(() => {
  const handleGetUnits = async () => {
    try {
      var units = await ApiMServer.post('/company/get/units', { "arrayIds": data });
      if (units.status === 200) {
        setUnits(units.data)
      }
    } catch (error) {
      console.log(error);
    }
  }
    handleGetUnits()
  }, [data])

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleLink = async (param) => {
    window.location.href = (`/units/${param}`);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {units &&
        <>
          <Typography color="textSecondary" gutterBottom variant="body2">
            {data.length} registros encontrados. Página {page + 1} de{' '}
            {Math.ceil(data.length / rowsPerPage)}
          </Typography>
          <Card>
            <CardContent className={classes.content}>
              <PerfectScrollbar>
                <div className={classes.inner}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className='col-sm-1' align="center">Nome</TableCell>
                        <TableCell className='col-sm-2' align="center">CNPJ</TableCell>
                        <TableCell align="center">E-mail</TableCell>
                        <TableCell align="center">Fone</TableCell>
                        <TableCell align="center">Ultimo acesso</TableCell>
                        <TableCell align="center">Ação</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {units &&
                        units.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                          .map((data) => (
                            <TableRow
                              hover
                              key={data._id}
                              selected={selectedData.indexOf(data.id) !== -1}>
                              <TableCell className='col-sm-1' align="center">
                                <div className={classes.nameCell}>
                                  <Avatar className={classes.avatar} src={data.avatar}>
                                    {getInitials(data.name)}
                                  </Avatar>
                                  <div>{data.name}</div>
                                </div>
                              </TableCell>
                              <TableCell align="center">{data.cnpj}</TableCell>
                              <TableCell align="center">{data.email}</TableCell>
                              <TableCell align="center">{data.fone}</TableCell>
                              <TableCell align="center">{dateFormat(data.updatedAt, "dd/mm/yyyy' 'HH:MM")}</TableCell>
                              <TableCell align="center" className='col-sm-3'>
                                <Button
                                  color="secondary"
                                  size="small"
                                  onClick={() => { handleLink(data._id) }}
                                  variant="outlined">
                                  Visualizar
                                </Button>
                                <Button
                                  disabled={permission === true ? false : true}
                                  className={classes.rmvButton}
                                  onClick={() => onRemoveButton(data._id)}
                                  size="small"
                                  variant="outlined">
                                  Remover
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </div>
              </PerfectScrollbar>
            </CardContent>
            <CardActions className={classes.actions}>
              <TablePagination
                component="div"
                count={data.length}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
                labelRowsPerPage="Linhas por página:"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 20, 40]}
              />
            </CardActions>
          </Card>
        </>
      }
    </div>
  );
};
export default Cooperatives;
