import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Notification({ data }) {
    useEffect(() => {
        const notify = () => {
            if (data) {
                toast.warn(
                    <div>
                        <div style={styles.header}>
                            {data.serial ?
                                <h5 style={styles.title}>{`Alerta para o equipamento: ${data?.serial}`}</h5>
                                :
                                <h5 style={styles.title}>Alerta de equipamento</h5>
                            }
                        </div>
                        <div style={styles.row}>
                            <p> <strong>Msg:</strong> {data.message} </p>
                        </div>
                    </div>,
                    {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: 'dark',
                    }
                );
            }
        };
        notify();
    }, [data]);

    return <ToastContainer />;
}

const styles = {
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    },
    icon: {
        marginRight: '10px',
        fontSize: '20px',
        color: '#ffcc00',
    },
    title: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#ffcc00',
        margin: 0,
    },
    row: {
        fontSize: '14px',
        marginBottom: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        color: '#ccc',
    },
    value: {
        fontWeight: 'bold',
        color: '#ffcc00',
    },
};

export default Notification;
