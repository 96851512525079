import React, { useState } from 'react';

import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';

import { Page } from 'components';
import { Header, Form } from './components';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3, 3, 6, 3)
  },
  aboutProject: {
    marginTop: theme.spacing(3)
  }
}));

const UnitsAdd = () => {
  const classes = useStyles();

  const [units, setUnits] = useState({
    name: '',
    cnpj: '',
    fone: '',
    email: ''
  });

  const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });

  const handleFieldChange = (event, field, value) => {
    event.persist && event.persist();
    setUnits(values => ({
      ...values,
      [field]: value
    }));
  };

  const updateAddressAndLocation = (newAddress, newLat, newLng) => {
    console.log(newAddress, newLat, newLng);
    setUnits(prevUnits => ({
      ...prevUnits,
      address: newAddress,
      location: {
        lat: String(newLat),
        lng: String(newLng)
      }
    }));
  };

  const handleSubmit = async () => {
    try {
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post('/company/create', units);
      setAlertMessage({ message: 'Adicionado com sucesso!', type: 'success' });
      setUnits({
        name: '',
        cnpj: '',
        fone: '',
        email: ''
      });
    } catch (e) {
      setAlertMessage({ message: 'Falha ao adicionar!', type: 'error' });
    }
  };

  return (
    <Page
      className={classes.root}
      title="Unidades"
    >
      <Header />
      { <Form
        alertMessage={alertMessage}
        className={classes.aboutProject}
        fieldChange={handleFieldChange}
        onSubmit={handleSubmit}
        units={units}
        setLocation={updateAddressAndLocation}
      />}
    </Page>
  );
};

export default UnitsAdd;