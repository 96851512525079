import React, { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { getToken } from 'actions/sessionActions';
import { Page } from 'components';
import { Download, Header, Results } from './components';
import { Button, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'utils/axios';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3, 3, 6, 3)
  },
  printarea: {},
  saveButton: {
    marginLeft: 10
  },
  header: {
    paddingTop: '10px',
    paddingLeft: '10px',
    marginBottom: '15px'
  },
  order: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const Relatorio = (props) => {
  const classes = useStyles();

  const [equipments, setEquipments] = useState({});
  let [loading, setLoading] = useState(true);

  const style = {
    position: 'fixed',
    top: '40%',
    left: '60%',
    transform: 'translate(-50%, -50%)'
  };

  let color = useState('#09D052');

  useEffect(() => {
    (async () => {
      var data1 = {}
      const filter = localStorage.getItem("EQUIP_FILTER");
      const globalEquipments = JSON.parse(localStorage.getItem("EQUIPMENTS"));

      await axios(process.env.REACT_APP_API_GERA_SENHA, getToken())
        .post(`/log/unlockfilter`, { "filter": JSON.parse(filter) })
        .then((response) => {
          var equipments = response.data;
          data1 = equipments

          let equipLog = JSON.parse(localStorage.getItem("tempEquipments"));

          equipments = equipments.map(equip => {
            let temp = Object.values(equipLog).find(e => e.serialnumber === equip.serialnumber) || null;
            if (temp) {
              equip.wf = temp.wf === 'OK' ? 'Sim' : "Não";
              equip.calph = temp.calph === 'OK' ? 'Sim' : "Não";
            }
            let e = globalEquipments.filter((i) => i.serialnumber === equip.serialnumber);
            if (e.length > 0) {
              equip.dcal = e[0].dcal;
            }
            return equip;
          });

          let users = equipments.map((equip) => {
            return equip.email;
          });

          axios(process.env.REACT_APP_API_MOTOMCO, getToken())
            .post('/usersfilter', { filter: users })
            .then((response) => {
              let users = response.data;
              for (let j = 0; j < equipments.length; j++) {
                equipments[j].user = users.find(
                  (element) => element.email === equipments[j].email
                );
              }
            });
        });

      await axios(process.env.REACT_APP_API_GLOBAL_SERVER, getToken())
        .post(`/equipment/filter`, { "filter": JSON.parse(filter) })
        .then((response) => {
          handleData(data1, response.data)
        })
      setLoading(false);
    })();
  }, []);

  const handleData = async (data1, data2) => {
    const data1Map = data1.reduce((acc, item) => {
      acc[item.sn] = item;
      return acc;
    }, {});

    const updatedData2 = data2.map(item => {
      const correspondingData1Item = data1Map[item.serialnumber];

      if (!correspondingData1Item) {
        return item;
      }

      let copo;
      const filCopo = Number(item.copo);

      if (filCopo === 0) {
        copo = "Sem dados";
      } else if (filCopo === 150) {
        copo = `Pequeno | ${filCopo}`;
      } else if (filCopo > 150) {
        copo = `Pequeno | ${filCopo}`;
      } else if (filCopo < 150) {
        copo = `Grande | ${filCopo}`;
      }

      copo = copo || ""

      return {
        ...item,
        copo,
        ...correspondingData1Item
      };
    });

    setEquipments(updatedData2);
  }

  const sortBySN = () => {
    equipments
      .sort(function (a, b) {
        return a.serialnumber.split('-')[1] - b.serialnumber.split('-')[1];
      })
      .sort();

    var list = [];
    equipments.forEach((e) => {
      list.push(e);
    });
    setEquipments(list);
  };

  const sortByDate = () => {
    equipments.sort(function (a, b) {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    });

    var list = [];
    equipments.forEach((e) => {
      list.push(e);
    });
    setEquipments(list);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <Page className={classes.root} title="Equipamentos">
      <Divider />
      <div className={classes.order}>
        <Typography variant="h5">Ordenar lista por:</Typography>

        <div style={{ display: 'inline-flex', marginTop: 10, flexDirection: 'row' }}>
          <Button
            className={classes.saveButton}
            color="primary"
            onClick={sortBySN}
            variant="contained">
            Número Serial
          </Button>

          <Button
            className={classes.saveButton}
            color="primary"
            onClick={sortByDate}
            variant="contained">
            Data
          </Button>
        </div>
      </div>
      <Divider />

      <div style={{ display: 'inline-flex', marginLeft: 10, flexDirection: 'row' }}>
        <Button
          style={{ marginRight: 10 }}
          color="primary"
          onClick={handlePrint}
          variant="contained">
          Imprimir
        </Button>

        {equipments.length && <Download data={equipments} />}
      </div>

      {
        equipments.length ?
          <div ref={componentRef} className={classes.printarea}>
            <Header className={classes.header} />
            {equipments && (
              <Results className={classes.results} data={equipments} />
            )}
          </div> :
          <div style={style}>
            <ClipLoader color={color} loading={loading} size={100} />
          </div>
      }

    </Page>
  );
};

export default Relatorio;
