/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';

import { checkAuth, logout } from './actions/sessionActions';

import Maps from 'views/Maps';
import Units from 'views/Units';
import UnitsAdd from 'views/UnitsAdd';
import UnitsDetail from 'views/UnitsDetail';
import CooperativesDetail from 'views/CooperativesDetail';
import Charts from 'views/Charts';
import Equipments from 'views/Equipments';
import EquipmentsQL from 'views/EquipmentsQL';
import EquipmentsAdd from 'views/EquipmentsAdd';
import EquipmentsDetail from 'views/EquipmentsDetail';
import EquipmentsSoftwareDetail from 'views/EquipmentsSoftwareDetail';
import EquipmentsDetailQL from 'views/EquipmentsDetailQL';
import Users from 'views/Users';
import UsersAdd from 'views/UsersAdd';
import UsersDetail from 'views/UsersDetail';
import Settings from 'views/Settings';
import Home from 'views/Home';
import Notifications from 'views/Notifications';
import Relatorio from 'views/Relatorio';
import UserPassword from 'views/UsersPassword';
import Cooperatives from 'views/Cooperatives';
import Suporte from 'views/Suporte';
import Conected from 'views/Suporte/components/Conected';

const routes = [
  {
    path: '/',
    exact: true,
    render: () =>
      checkAuth() ? <Redirect to="/home" /> : <Redirect to="/auth/login" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/home',
        exact: true,
        render: (props) =>
          checkAuth() ? <Home {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/users',
        exact: true,
        render: (props) =>
          checkAuth() ? <Users {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/userspassword',
        exact: true,
        render: (props) =>
          checkAuth() ? <UserPassword {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/users/add',
        exact: true,
        render: (props) =>
          checkAuth() ? <UsersAdd {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/maps',
        exact: true,
        render: (props) =>
          checkAuth() ? <Maps {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/charts',
        exact: true,
        render: (props) =>
          checkAuth() ? <Charts {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/support',
        exact: true,
        render: (props) =>
          checkAuth() ? <Suporte {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/units',
        exact: true,
        render: (props) =>
          checkAuth() ? <Units {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/units/add',
        exact: true,
        render: (props) =>
          checkAuth() ? <UnitsAdd {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/units/:id',
        exact: true,
        render: (props) =>
          checkAuth() ? <UnitsDetail {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/support/:id',
        exact: true,
        render: (props) =>
          checkAuth() ? <Conected {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/cooperatives',
        exact: true,
        render: (props) =>
          checkAuth() ? <Cooperatives {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/cooperatives/:id',
        exact: true,
        render: (props) =>
          checkAuth() ?
            <CooperativesDetail {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/equipments',
        exact: true,
        render: (props) =>
          checkAuth() ? <Equipments {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/equipmentsQL',
        exact: true,
        render: (props) =>
          checkAuth() ? <EquipmentsQL {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/equipments/add',
        exact: true,
        render: (props) =>
          checkAuth() ? <EquipmentsAdd {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/equipments/:serial',
        exact: true,
        render: (props) =>
          checkAuth() ? <EquipmentsDetail {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/equipments/model/:model',
        exact: true,
        render: (props) =>
          checkAuth() ? <EquipmentsSoftwareDetail {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/relatorio',
        exact: true,
        render: (props) =>
          checkAuth() ? <Relatorio {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/equipmentsQL/:serial',
        exact: true,
        render: (props) =>
          checkAuth() ? <EquipmentsDetailQL {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/notifications',
        exact: true,
        render: (props) =>
          checkAuth() ? <Notifications {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/users/:id',
        exact: true,
        render: (props) =>
          checkAuth() ? <UsersDetail {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/settings',
        exact: true,
        render: (props) =>
          checkAuth() ? <Settings {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/settings/:tab',
        exact: true,
        render: (props) =>
          checkAuth() ? <Settings {...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/logout',
        exact: true,
        render: () =>
          logout() ? <Redirect to="/auth/login" /> : <Redirect to="/" />
      }
    ]
  }
];

export default routes;
