import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { makeStyles } from '@material-ui/core/styles';

import {
  Link,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography
} from '@material-ui/core';

import getInitials from 'utils/getInitials';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 70px)',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  }
}));

const AvatarList = props => {
  const { data } = props;
  const classes = useStyles();

  return (
    <PerfectScrollbar>
      <List className={classes.root}>
        {data &&
          data.map(item => {
            return (
              <>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar>{getInitials(item.name)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Link
                        component={RouterLink}
                        to={`/analytics/${item.serial}`}>
                        {`${item.name} (${item.serial}) `}
                      </Link>
                    }
                    secondary={
                      <React.Fragment>
                        {item.grain && (
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary">
                              {item.grain}
                            </Typography>
                            <br />
                          </>
                        )}
                        {item.humidity !== '' && (
                          <>
                            {`Umidade: ${item.humidity.toFixed(1)}%`}
                            <br />
                          </>
                        )}

                        {item.unity
                          ? `Unidade: ${item.unity.name}`
                          : `Empresa: ${item.company.name}`}
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            );
          })}
      </List>
    </PerfectScrollbar>
  );
};

export default AvatarList;
