import React, { useState, useEffect } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import { MapSettings } from './components';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Map = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const { name, email, companyName, companyCNPJ } = JSON.parse(
      localStorage.getItem('userData')
    );
    setProfile({
      name,
      email,
      companyName,
      companyCNPJ
    });
  }, []);

  if (!profile) {
    return null;
  }

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}>
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <MapSettings profile={profile} />
      </Grid>
    </Grid>
  );
};

Map.propTypes = {
  className: PropTypes.string
};

export default Map;
