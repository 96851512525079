import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { Alert } from 'components';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, CardActions, TextField, Grid, Divider, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '30px'
  },
  alert: {
    marginBottom: theme.spacing(3)
  },
  formGroup: {
    marginBottom: theme.spacing(3)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  fieldHint: {
    margin: theme.spacing(1, 0)
  },
  tags: {
    marginTop: theme.spacing(1),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  dateField: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const Form = props => {
  const {
    className,
    alertMessage,
    fieldChange,
    onSubmit,
    roles,
    user,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <React.Fragment>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent>
          <form>
            {alertMessage.message && (
              <Alert
                className={classes.alert}
                message={alertMessage.message}
                variant={alertMessage.type}
              />
            )}
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                sm={6}
                xs={12}
              >
                <div className={classes.formGroup}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    label="Nome"
                    name="name"
                    onChange={event =>
                      fieldChange(event, 'name', event.target.value)
                    }
                    value={user.name}
                    variant="outlined"
                  />
                </div>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <div className={classes.formGroup}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    label="E-mail"
                    name="email"
                    onChange={event =>
                      fieldChange(event, 'email', event.target.value)
                    }
                    value={user.email}
                    variant="outlined"
                  />
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                sm={6}
                xs={12}
              >
                <div className={classes.formGroup}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    label="Fone"
                    name="fone"
                    onChange={event =>
                      fieldChange(event, 'fone', event.target.value)
                    }
                    value={user.username}
                    variant="outlined"
                  />
                </div>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <div className={classes.formGroup}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    label="Senha"
                    name="password"
                    onChange={event =>
                      fieldChange(event, 'password', event.target.value)
                    }
                    type="password"
                    value={user.password}
                    variant="outlined"
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.saveButton}
            color="primary"
            onClick={() => onSubmit()}
            variant="contained"
          >
            Salvar
          </Button>
          <Button
            color="secondary"
            component={RouterLink}
            onClick={() => onSubmit()}
            to={'/users'}
            variant="contained"
          >
            Cancelar
          </Button>
        </CardActions>
      </Card>
    </React.Fragment>
  );
};

Form.propTypes = {
  alertMessage: PropTypes.object,
  className: PropTypes.string,
  fieldChange: PropTypes.object,
  onSubmit: PropTypes.func,
  roles: PropTypes.object,
  user: PropTypes.object
};

export default Form;
