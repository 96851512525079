exports.timeSince = (date) => {

    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + (Math.floor(interval) > 1 ? " anos" : " ano");
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) > 1 ? + Math.floor(interval) + " meses" : "1 mês";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return "1 mês";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return "1 mês";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return "1 mês";
    }

    return "Erro";
}


// exports.timeSince = (date) => {

//     var seconds = Math.floor((new Date() - date) / 1000);

//     var interval = seconds / 31536000;

//     if (interval > 1) {
//         return Math.floor(interval) + " anos";
//     }
//     interval = seconds / 2592000;
//     if (interval > 1) {
//         return Math.floor(interval) + " meses";
//     }
//     interval = seconds / 86400;
//     if (interval > 1) {
//         return Math.floor(interval) + " dias";
//     }
//     interval = seconds / 3600;
//     if (interval > 1) {
//         return Math.floor(interval) + " horas";
//     }
//     interval = seconds / 60;
//     if (interval > 1) {
//         return Math.floor(interval) + " minutos";
//     }
//     return Math.floor(seconds) + " segundos";
// }