import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import dateformat from 'utils/dateformat';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  head: {
    background: '#DDDDDD'
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  }, 
  table: {}
}));

const Results = (props) => {
  const classes = useStyles();
  const { className, data, title, ...rest } = props;

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {data.length > 0 ? (
        <Card>
          <CardHeader title={title} />
          <Divider />
          <CardContent className={classes.content}>
            <div className={classes.inner}>
              <div>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.head}>
                      <TableCell align='center'>SERIAL</TableCell>
                      <TableCell align='center'>SOFTWARE</TableCell>
                      <TableCell align='center'>DATA</TableCell>
                      <TableCell align='center'>E-MAIL</TableCell>
                      <TableCell align='center'>ENDEREÇO</TableCell>
                      <TableCell align='center'>LATITUDE</TableCell>
                      <TableCell align='center'>LONGITUDE</TableCell>
                    </TableRow>
                  </TableHead>

                  {data && data.map((data) => (
                    <TableBody key={data._id}>
                      <TableRow hover>
                        <TableCell align='center'>{data.serialnumber ? data.serialnumber : '---'}</TableCell>
                        <TableCell align='center'>{data.versaosw ? data.versaosw : '---'}</TableCell>
                        <TableCell align='center'>{dateformat(data.createdAt, true)}</TableCell>
                        <TableCell align='center'>{data.email ? data.email : '---'}</TableCell>
                        <TableCell align='center'>{data.address ? data.address : '---'}</TableCell>
                        <TableCell align='center'>{data.lat ? data.lat : '---'}</TableCell>
                        <TableCell align='center'>{data.lng ? data.lng : '---'}</TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
                </Table>
              </div>
            </div>
          </CardContent>
        </Card>
      ) : <h3> Sem dados registrados. </h3>}
    </div>
  );
};

export default Results;
