import React, { useState, useEffect } from 'react';

import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';

import { Page } from 'components';
import { Header, Form } from './components';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3, 3, 6, 3)
  },
  aboutAuthor: {
    marginTop: theme.spacing(3)
  },
  aboutProject: {
    marginTop: theme.spacing(3)
  },
  projectCover: {
    marginTop: theme.spacing(3)
  },
  projectDetails: {
    marginTop: theme.spacing(3)
  },
  preferences: {
    marginTop: theme.spacing(3)
  },
  actions: {
    marginTop: theme.spacing(3)
  },
  saveButton: {
    marginRight: '10px'
  }
}));

const EquipmentsAdd = () => {
  const classes = useStyles();

  const [unities, setUnities] = useState();

  const [equipments, setEquipments] = useState({
    name: '',
    model: '',
    serialnumber: '',
    ip: '',
    status: '',
    active: '',
    position: '',
    grain: '',
    idealRangeMin: '',
    idealRangeMax: '',
    sha1Curve: 'sha1',
    unity_id: ''
  });

  const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });

  useEffect(() => {
    const { companyId } = JSON.parse(localStorage.getItem('userData'));
    setEquipments({
      ...equipments,
      company_id: companyId
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      const { companyId } = JSON.parse(localStorage.getItem('userData'));

      const { data: unities } = await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).get(
        `/unities/companies/${companyId}`
      );
      setUnities(unities);
    })();
  }, []);

  const handleFieldChange = (event, field, value) => {
    event.persist && event.persist();
    if (field === 'status') value = value === 'true';
    if (field === 'active') value = value === 'true';
    if (field === 'idealRangeMin') value = parseFloat(value);
    if (field === 'idealRangeMax') value = parseFloat(value);

    setEquipments(values => ({
      ...values,
      [field]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post('/equipments', equipments);
      setAlertMessage({ message: 'Adicionado com sucesso!', type: 'success' });
      setEquipments({
        name: '',
        model: '',
        serialnumber: '',
        ip: '',
        status: '',
        active: '',
        position: '',
        grain: '',
        idealRangeMin: '',
        idealRangeMax: '',
        unity_id: ''
      });
    } catch (e) {
      setAlertMessage({ message: 'Falha ao adicionar!', type: 'error' });
    }
  };

  return (
    <Page className={classes.root} title="Equipamentos">
      <Header />
      <Form
        alertMessage={alertMessage}
        className={classes.aboutProject}
        equipments={equipments}
        fieldChange={handleFieldChange}
        onSubmit={handleSubmit}
        unities={unities}
      />
    </Page>
  );
};

export default EquipmentsAdd;
