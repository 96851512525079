var dateFormat = require("dateformat");
dateFormat.i18n = {
    dayNames: [
        "Dom",
        "Seg",
        "Ter",
        "Qua",
        "Qui",
        "Sex",
        "Sáb",
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
    ],
    monthNames: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ],
    timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
};

const instance = (date, short) => {
    if (short)
        return dateFormat(date, "dd/mm/yyyy h:MM TT");
    else
        return dateFormat(date, "dddd, d, mmmm, yyyy, h:MM:ss TT");
}

export default instance;