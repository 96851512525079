import { Button } from '@material-ui/core';
import React from 'react';
import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Download = (props) => {
  const { data } = props;

  return (
    <div>
      {data && data.length > 0 ? (
        <ExcelFile element={<Button variant='contained' color='primary'>Download</Button>}>
          <ExcelSheet data={data} name="Relatório">
            <ExcelColumn label="Data" value="updatedAt" />
            <ExcelColumn label="Número Serial" value="serialnumber" />
            <ExcelColumn label="ID do Copo" value="id" />
            <ExcelColumn label="Versão de Software" value="versaosw" />
            <ExcelColumn label="Nome" value="name" />
            <ExcelColumn label="Email" value="email" />
            <ExcelColumn label="Latitude" value="lat" />
            <ExcelColumn label="Longitude" value="lng" />
            <ExcelColumn label="Chip WIFI" value="wf" />
            <ExcelColumn label="PH Calibrado" value="calph" />
            <ExcelColumn label="Data de calibração" value="dcal" />
            <ExcelColumn label="Tamanho do copo" value="copo" />
          </ExcelSheet>
        </ExcelFile>
      ) : null}
    </div>
  );
};

export default Download;
