import React, { useState, useRef } from 'react';
import useSupercluster from "use-supercluster";
import GoogleMapReact from "google-map-react";
import { Marker } from 'react-google-maps';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
import InfoWindow from '../InfoWindow';

const useStyles = makeStyles(() => ({
  root: {},
  cluster_marker: { "color": "#fff", "background": "#1978c8", "borderRadius": "50%", "padding": "10px", "display": "flex", "justifyContent": "center", "alignItems": "center" },
  cluster_mark: { "color": "#fff", "background": "#1978c8", "borderRadius": "5px", "padding": "2px", "display": "flex", "justifyContent": "center", "alignItems": "center", "width": "32px" },
  equip_marker_img: { "width": "26px", "height": "auto", "borderRadius": "5px", "background": "#539a73a7", "padding": "1px" },
  equip_marker_img_located: { "width": "26px", "height": "auto", "borderRadius": "5px", "background": "#ffe07aa7", "padding": "1px" },
  card: {
    width: '180px'
  },
  title: {
    fontSize: 12
  },
  pos: {
    marginBottom: 12
  },
  btn: {
    marginTop: '20px'
  },
  online: {
    color: colors.green[700]
  },
  offline: {
    color: colors.red[600]
  }
}))

const Map = (props) => {
  const classes = useStyles();
  const mapRef = useRef();

  const { points, mapPoints } = props
  const [bounds, setBounds] = useState(null)
  const [zoom, setZoom] = useState(4)

  const [center, setCenter] = useState({
    lat: -14.4284,
    lng: -55.2733
  });

  const handleCloseInfoWindown = () => { setSelectedEquipment(null) }

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 40, maxZoom: 20 }
  });

  const [selectedEquipment, setSelectedEquipment] = useState(null)

  return (
    <div style={{ height: '100%', width: '100%' }}>

      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
        defaultCenter={{
          lat: -14.4284,
          lng: -55.2733
        }}
        center={center}
        zoom={zoom}
        defaultZoom={4}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ]);
        }}
      >
        {clusters &&
          clusters.map(marker => {

            const [longitude, latitude] = marker.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount
            } = marker.properties;

            if (mapPoints === true) {
              return (
                <Marker
                  key={marker.serial}
                  onClick={() => {
                    setSelectedEquipment(marker);
                  }}
                  lat={latitude}
                  lng={longitude}
                >
                  <div>
                    {!pointCount ? '' :
                      <div className={classes.cluster_mark}>{pointCount}</div>
                    }
                    <img className="" alt="" src={marker ? '/images/redMarker.png' : ''} onClick={() => {
                      setSelectedEquipment(marker.properties.data)
                    }} />
                  </div>
                </Marker>
              )
            }

            if (isCluster) {
              return (
                <Marker
                  key={marker.serial}
                  onClick={() => {
                    setSelectedEquipment(marker);
                  }}
                  lat={latitude}
                  lng={longitude}
                >
                  <div
                    className={classes.cluster_marker}
                    style={{
                      "width": `${15 + (pointCount / points.length) * 30}px`,
                      "height": `${15 + (pointCount / points.length) * 30}px`,
                      "zIndex": 999
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(marker.id),
                        20
                      );
                      mapRef.current.setZoom(expansionZoom);
                      mapRef.current.panTo({ lat: latitude, lng: longitude });
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              )
            }

            return (
              <Marker
                key={marker.serial}
                onClick={() => {
                  setSelectedEquipment(marker);
                }}
                lat={latitude}
                lng={longitude}
              >
                {marker.located ?
                  <img alt="" src={marker.properties.data.serial.startsWith("CP") ? '/images/cp.png'
                    : marker.properties.data.serial.startsWith("SI") || marker.properties.data.serial.startsWith("ES") ? '/images/esi.png'
                      : marker.properties.data.serial.startsWith("BI") || marker.properties.data.serial.startsWith("FB") ? '/images/fbi.png' :
                        marker.properties.data.serial.startsWith("EE") ? '/images/ee.png' :
                          '/images/blueMarker.png'} className={classes.equip_marker_img_located} onClick={() => {
                            setSelectedEquipment(marker.properties.data)
                          }} />
                  :
                  <img alt="" src={marker.properties.data.serial.startsWith("CP") ? '/images/cp.png'
                    : marker.properties.data.serial.startsWith("SI") || marker.properties.data.serial.startsWith("ES") ? '/images/esi.png'
                      : marker.properties.data.serial.startsWith("BI") || marker.properties.data.serial.startsWith("FB") ? '/images/fbi.png' :
                        marker.properties.data.serial.startsWith("EE") ? '/images/ee.png' :
                          '/images/blueMarker.png'} className={classes.equip_marker_img} onClick={() => {
                            setSelectedEquipment(marker.properties.data)
                          }} />
                }
              </Marker>
            )
          })}

        {selectedEquipment &&
          <InfoWindow
            setCenter={setCenter}
            close={handleCloseInfoWindown}
            equipment={selectedEquipment}
            lat={selectedEquipment.lat}
            lng={selectedEquipment.lng}
            located={selectedEquipment.located}
            text="My Marker"
          />
        }

      </GoogleMapReact>
    </div>
  )
}
export default Map;
