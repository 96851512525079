import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import ClipLoader from "react-spinners/ClipLoader";

const useStyles = makeStyles(() => ({
  root: {}
}));

const Header = (props) => {
  const { className, handleGetRepor, handleOpenCompany, loading, ...rest } = props;
  const { permission } = JSON.parse(localStorage.getItem('userData'));
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid
        alignItems="flex-end"
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            Gerenciamento
          </Typography>
          <Typography
            component="h1"
            variant="h3"
          >
            Cooperativas
          </Typography>
        </Grid>
        <Grid item>
          <Button
            disabled={permission.units.write === true ? false : true}
            style={{ "marginRight": 15 }}
            color="primary"
            variant="contained"
            onClick={() => { handleOpenCompany() }}
          >
            {'Visualização do Cliente'}
          </Button>
          <Button
            disabled={permission.units.write === true ? false : true}
            color="primary"
            variant="contained"
            onClick={() => { handleGetRepor() }}
          >
            {loading ? <> <ClipLoader size={20} color="white" /> {'Relatório Gerencial'} </> : 'Relatório Gerencial'}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};
export default Header;