// src/MeuComponente.js
import React, { useEffect, useState } from 'react';
import { Header } from './components';
import { makeStyles } from '@material-ui/styles';
import { SearchBar, Page } from 'components';
import Results from './components/Results';
import CircularProgress from '@material-ui/core/CircularProgress';

import Api from 'services/Api';
import ApiGSenha from 'services/ApiGSenha';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  },
  alert: {
    marginTop: '20px'
  }
}));

const Suporte = () => {
  const classes = useStyles();

  const user = JSON.parse(localStorage.getItem('userData'))
  if (user && user.permission.support.read === false) {
    window.location.replace('/home')
  }

  const [filterEquipaments, setFilterEquipaments] = useState([]);
  const [allEquipaments, setAllEquipaments] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [permission, setPermission] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      var data = []
      try {
        data = await Api.get('/connection/connected');
        data = data.data
        if (data.length > 0) {
          handleDetailEquipaments(data.sort((a, b) => a.localeCompare(b)));
        }
        setLoading(false)
      } catch (error) {
        console.error('Erro ao buscar os dados:', error);
        setLoading(false)
      }
    };
    fetchData();

    const interval1 = setInterval(() => {
      fetchData();
    }, 60000);
    return () => { clearInterval(interval1) };
  }, []);

  const handleDetailEquipaments = async (equipments) => {
    try {
      const { data } = await ApiGSenha.post(`/log/unlockfilter`, { filter: equipments });
      setFilterEquipaments(data)
      setAllEquipaments(data)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
    setLoading(false)
  };

  const handleSearch = () => {
    if (searchData === '') {
      setAllEquipaments(filterEquipaments);
    } else {
      const equipamentsSearch = [];
      filterEquipaments.forEach((company) => {
        if (
          (company.serialnumber && company.serialnumber.toLowerCase().includes(searchData.toLowerCase())) ||
          (company.email && company.email.toLowerCase().includes(searchData.toLowerCase()))
        ) {
          equipamentsSearch.push(company)
        }
      });
      setAllEquipaments(equipamentsSearch);
    }
  };

  const handleSearchData = (e) => {
    setSearchData(e.target.value)
  };

  return (
    <Page className={classes.root} title="Suporte">
      <Header />
      <SearchBar
        onSearch={handleSearch}
        onSearchData={handleSearchData}
        searchData={searchData}
      />

      {loading ? (
        <div style={{ textAlign: 'center', marginTop: 30 }}>
          <CircularProgress />
        </div>
      ) : null}

      {allEquipaments && (
        <Results
          className={classes.results}
          data={allEquipaments}
          permission={permission}
        />
      )}
    </Page >
  );
}

export default Suporte;
