import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  colors
} from '@material-ui/core';

import getInitials from 'utils/getInitials';
import dateformat from 'utils/dateformat';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  ql: {
    marginLeft: '10px'
  },
  rmvButton: {
    borderColor: '#FE2020',
    color: '#FE2020',
    marginLeft: '10px',
    background: 'transparent'
  },
  analyticsButton: {
    borderColor: colors.orange[700],
    color: colors.orange[700],
    marginRight: '10px',
    background: 'transparent'
  },
  online: {
    color: colors.green[700]
  },
  offline: {
    color: colors.red[600]
  }
}));

const Results = (props) => {
  const {
    className,
    data,
    onRemoveButton,
    nEquipments,
    getEquipments,
    ...rest
  } = props;

  const classes = useStyles();

  const [selectedData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, page) => {
    setPage(page);
    getEquipments(page + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography
        color="textSecondary"
        gutterBottom
        variant="body2"
      >
        {data.length} registros encontrados. Página {page + 1} de{' '}
        {Math.ceil(data.length / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader title="Equipamentos" />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">MODELO</TableCell>
                    <TableCell align="center">SERIAL</TableCell>
                    <TableCell align="center">ÚLTIMA ATUALIZAÇÃO</TableCell>
                    <TableCell align="center">TESTE</TableCell>
                    <TableCell align="center">POSIÇÃO</TableCell>
                    <TableCell align="center">AÇÃO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map(data => (
                    <TableRow
                      hover
                      key={data._id}
                      selected={selectedData.indexOf(data.id) !== -1}>
                      <TableCell align="center">
                        <div className={classes.nameCell}>
                          <Avatar className={classes.avatar} src={data.avatar}>
                            {getInitials(data.serialnumber)}
                          </Avatar>
                          <div>
                            <Link
                              color="inherit"
                              component={RouterLink}
                              to={`/equipmentsQL/${data.serialnumber}`}
                              variant="h5">
                              {data.serialnumber.substring(0, 4)}
                            </Link>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="center">{data.serialnumber}</TableCell>
                      <TableCell align="center">{dateformat(data.updatedAt)}</TableCell>
                      <TableCell align="center">{data.count ? data.count : '---'}</TableCell>
                      <TableCell align="center">{data.position}</TableCell>
                      <TableCell align="center">
                        <Button
                          color="secondary"
                          component={RouterLink}
                          size="small"
                          to={`/equipmentsQL/${data.serialnumber}`}
                          variant="outlined">
                          Visualizar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={data.length}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
            labelRowsPerPage="Linhas por página:"
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 20, 40]}
          //count={nEquipments}
          />
        </CardActions>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired
};

Results.defaultProps = {
  data: []
};

export default Results;
