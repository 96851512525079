import React from 'react';
import { Typography } from '@material-ui/core';

const Header = () => {

  return (
    <div>
      <Typography component="h2" gutterBottom variant="overline">
        gerenciamento
      </Typography>
      <Typography component="h1" variant="h3">
        Softwares
      </Typography>
    </div>
  );
};

export default Header;
