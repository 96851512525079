import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';

let autoComplete;

const useStyles = makeStyles((theme) => ({
  input: {
    border: '0px',
    borderRadius: '4px',
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '13px',
    paddingRight: '13px',
    width: '100%',
    fontSize: '15px',
    color: '#263238'
  }
}));

const loadScript = (url, callback) => {
  let script = document.createElement('script');
  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

const SearchLocationInput = (props) => {
  const { setLocation, addr } = props;

  const classes = useStyles();

  const [query, setQuery] = useState('');
  const autoCompleteRef = useRef(null);

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { componentRestrictions: { country: ['br', 'us', 'py', 'ar'] } }
    );
    autoComplete.setFields([
      'address_components',
      'formatted_address',
      'geometry'
    ]);
    autoComplete.addListener('place_changed', () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);

    setLocation(query, addressObject.geometry.location.lat(), addressObject.geometry.location.lng());
  }

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  useEffect(() => {
    if (addr) {
      setQuery(addr);
    }
  }, [addr]);

  return (
    <div className="search-location-input">
      <input
        className={classes.input}
        ref={autoCompleteRef}
        onChange={(event) => setQuery(event.target.value)}
        placeholder="Endereço"
        value={query}
      />
    </div>
  );
};

export default SearchLocationInput;
