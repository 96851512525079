import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  colors
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  ql: {
    marginLeft: '10px'
  },
  rmvButton: {
    borderColor: '#FE2020',
    color: '#FE2020',
    marginLeft: '10px',
    background: 'transparent'
  },
  analyticsButton: {
    borderColor: colors.orange[700],
    color: colors.orange[700],
    marginRight: '10px',
    background: 'transparent'
  },
  online: {
    color: colors.green[700]
  },
  offline: {
    color: colors.red[600]
  }
}));

const Equipments = (props) => {
  const { className, data, onRemoveButton, permission, ...rest } = props;
  const classes = useStyles();

  const [selectedData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {data.length} registros encontrados. Página {page + 1} de{' '}
        {Math.ceil(data.length / rowsPerPage)}
      </Typography>
      <Card>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SERIAL</TableCell>
                    <TableCell align="center">PLANO</TableCell>
                    <TableCell align="center">ATIVAÇÃO</TableCell>
                    <TableCell align="center">MODO</TableCell>
                    <TableCell align="center">WI-FI</TableCell>
                    <TableCell align="center">PH CALIBRADO</TableCell>
                    <TableCell align="center">DATA CALIBRAÇÃO</TableCell>
                    <TableCell align="center">ISENÇÃO DE TAXAS</TableCell>
                    <TableCell align="center">AÇÃO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                      .map((data) => (
                        <TableRow
                          hover
                          key={data.serialnumber}
                          selected={selectedData.indexOf(data.id) !== -1}>
                          <TableCell className='col-sm-2' align="center">{data.serialnumber}</TableCell>
                          <TableCell align="center">{data.plan ? data.plan : "NORMAL"}</TableCell>
                          <TableCell align="center">{parseInt(data.daystoactivate, 16)} dias</TableCell>
                          <TableCell align="center">{data.mode === 'OWNER' ? 'Proprietário' : 'Locação'}</TableCell>
                          <TableCell align="center">{data.wf === 'OK' ? 'Sim' : 'Não'}</TableCell>
                          <TableCell align="center">{data.calph === 'OK' ? 'Sim' : 'Não'}</TableCell>
                          <TableCell align="center">
                            {(() => {
                              var d = new Date(data.dcal);
                              let seconds = Math.floor((Date.now() - d.getTime()) / 1000);
                              return seconds > 31536000 ? <div style={{ color: "red" }}>{data.dcal}</div> : <div>{data.dcal}</div>
                            })()}
                          </TableCell>
                          <TableCell align="center">{data.isentkey === true ? 'Sim' : 'Não'}</TableCell>
                          <TableCell align="center" className='col-sm-3'>
                            <Button
                              color="secondary"
                              component={RouterLink}
                              size="small"
                              to={`/equipments/${data.serialnumber}`}
                              variant="outlined">
                              Visualizar
                            </Button>
                            <Button
                              disabled={permission === true ? false : true}
                              className={classes.rmvButton}
                              onClick={() => onRemoveButton(data.serialnumber)}
                              size="small"
                              variant="outlined">
                              Remover
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={data.length}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
            labelRowsPerPage="Linhas por página:"
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 20, 40]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

Equipments.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired
};

Equipments.defaultProps = {
  data: []
};

export default Equipments;
