import axios from 'axios';

const instance = (baseURL, token) =>
  axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

export default instance;
