/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import clsx from 'clsx';

import validate from 'validate.js';
import PropTypes from 'prop-types';

import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';
import { Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

const schema = {
  title: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  message: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  url: {
    presence: { allowEmpty: true, message: 'is required' }
  },
  filter: {
    presence: { allowEmpty: false, message: 'is required' }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    margin: '40px',
  },
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%'
  }
}));

const NotificationForm = props => {
  const { className, ...rest } = props;
  const classes = useStyles();


  const { permission } = JSON.parse(localStorage.getItem('userData'));

  const [emails, setEmails] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [key, setKey] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);
  useEffect(() => {
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).get("emails");
      data.push("Todos");
      data.push("SIMT");
      data.push("BIMT");
      data.push("CPMT");
      data.push("EEMT");
      data.push("ESMR");
      setEmails(data.filter(i => i));
    })();
  }, []);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();

    var filter = selectedEmails;
    if (!(Array.isArray(filter) && filter.length)) {
      alert("Escolha o filtro");
      return;
    }

    if (filter.includes("Todos")) {
      filter = [];
    }

    const payload = {}

    let screen = formState.values.url === null || formState.values.url === '' ? "login" : "site"

    payload.data = {
      "title": formState.values.title,
      "body": formState.values.message,
      "screen": screen,
      "url": formState.values.url,
      "id": 12
    }
    payload.notification = {
      "title": formState.values.title,
      "body": formState.values.message
    }

    payload.filter = {
      "list": filter
    }

    let credentials = JSON.parse(localStorage.getItem("userData"));

    payload.email = credentials.email;
    payload.password = credentials.password;

    try {
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post('/notificationapp', payload);
      alert('Notificação enviada com sucesso!');
    } catch (error) {
      console.log(error);
      if (error.response) {
        alert(error.response.data);
      } else {
        alert('Falha ao enviar!');
      }
    }
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <form
      {...rest}
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit}
    >
      <div className={classes.fields}>
        <TextField
          autoComplete="off"
          error={hasError('title')}
          fullWidth
          helperText={
            hasError('title') ? 'Insira um título' : null
          }
          label="Título"
          name="title"
          onChange={handleChange}
          value={formState.values.title || ''}
          variant="outlined"
        />
        <TextField
          error={hasError('message')}
          fullWidth
          helperText={hasError('message') ? 'Insira uma mensagem' : null}
          label="Mensagem"
          name="message"
          onChange={handleChange}
          value={formState.values.message || ''}
          variant="outlined"
        />

        <TextField
          error={hasError('message')}
          fullWidth
          helperText={hasError('message') ? 'Insira uma mensagem' : null}
          label="Url"
          name="url"
          onChange={handleChange}
          value={formState.values.url || ''}
          variant="outlined"
        />

        <Autocomplete
          multiple
          key={key}
          id="combo-box"
          options={emails}
          getOptionLabel={(option) => option}
          style={{ marginBottom: 10, width: '50%' }}
          renderInput={(params) => (
            <TextField {...params} label="Filtro" variant="outlined" />
          )}
          onChange={(event, newValue) => {
            setSelectedEmails(newValue);
          }}
        />

      </div>
      <Button
        className={classes.submitButton}
        color="secondary"
        disabled={permission.notifications.write === true ? false : true}
        onClick={handleSubmit}
        size="large"
        variant="contained"
      >
        Enviar
      </Button>
    </form>
  );
};

NotificationForm.propTypes = {
  className: PropTypes.string,
  onLogin: PropTypes.func
};

export default NotificationForm;
