import './App.css'
import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { renderRoutes } from 'react-router-config';
import { WebSocketProvider } from 'hooks/webSocketContext';
import { ScrollReset, GoogleAnalytics } from './components';
import MomentUtils from '@date-io/moment';
import theme from './theme';
import routes from './routes';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './assets/scss/index.scss';

import 'bootstrap/dist/css/bootstrap.min.css';

const history = createBrowserHistory();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <WebSocketProvider>
          <Router history={history}>
            <ScrollReset />
            <GoogleAnalytics />
            {renderRoutes(routes)}
          </Router>
        </WebSocketProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
