import React from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  TextField,
  Grid,
  Divider
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '30px'
  },
  alert: {
    marginBottom: theme.spacing(3)
  },
  formGroup: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  fieldHint: {
    margin: theme.spacing(1, 0)
  },
  tags: {
    marginTop: theme.spacing(1),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  dateField: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  textField: {
    margin: theme.spacing(1)
  },
  label: {
    margin: theme.spacing(2),
    fontSize: 21
  }
}));

const Form2 = props => {
  const {
    className,
    alertMessage,
    unlock,
    fieldChange,
    onSubmit,
    unities,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <form>
          <label className={classes.label}>Última ativação</label>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.textField}
                  aria-readonly="true"
                  autoComplete="off"
                  fullWidth
                  label="Nome"
                  name="name"
                  value={unlock.name ? unlock.name : ''}
                  variant="outlined"
                  disabled={true}
                />
                <TextField
                  className={classes.textField}
                  aria-readonly="true"
                  fullWidth
                  label="E-mail"
                  name="email"
                  value={unlock.email ? unlock.email : ''}
                  variant="outlined"
                  disabled={true}
                />

                <TextField
                  className={classes.textField}
                  aria-readonly="true"
                  fullWidth
                  label="Data"
                  name="date"
                  value={unlock.updatedAt ? unlock.updatedAt : ''}
                  variant="outlined"
                  disabled={true}
                />

                <TextField
                  className={classes.textField}
                  aria-readonly="true"
                  label="Latitude"
                  name="lat"
                  value={unlock.lat ? unlock.lat : ''}
                  variant="outlined"
                  disabled={true}
                />

                <TextField
                  className={classes.textField}
                  aria-readonly="true"
                  label="Longitude"
                  name="lng"
                  value={unlock.lng ? unlock.lng : ''}
                  variant="outlined"
                  disabled={true}
                />

                <TextField
                  className={classes.textField}
                  aria-readonly="true"
                  fullWidth
                  label="Endereço"
                  name="address"
                  value={unlock.address ? unlock.address : ''}
                  variant="outlined"
                  disabled={true}
                />
              </div>
            </Grid>
          </Grid>
        </form>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default Form2;
