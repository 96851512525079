import React, { useState, useEffect } from 'react';

import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';

import { Page, SearchBar } from 'components';
import { Header, Results } from './components';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  }
}));

const UserPassword = () => {
  const classes = useStyles();

  const user = JSON.parse(localStorage.getItem('userData'))
  if (user) {
    if (user.permission.userspassword.read === false) {
      window.location.replace('/home')
    }
  }

  const [users, setUsers] = useState([]);
  const [tempUsers, setTempUsers] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [searchData, setSearchData] = useState('');
  const [userId, setUserId] = useState('');
  const [permission, setPermission] = useState(false);

  const handleClickOpen = (id) => {
    setUserId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setUserId('');
    setOpen(false);
  };

  const getUsers = () => {
    (async () => {
      const { data: users } = await axios(
        process.env.REACT_APP_API_MOTOMCO,
        getToken()
      ).get('/userspassword');
      setUsers(users);
      setTempUsers(users);
    })();
  };

  const removeUserPermission = () => {
    (async () => {
      setOpen(false);
      await axios(
        process.env.REACT_APP_API_MOTOMCO,
        getToken()
      ).post(`/removepasswordacess`, { id: userId, token: user.token });
      getUsers();
    })();
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    const { permission } = JSON.parse(localStorage.getItem('userData'));
    setPermission(permission.userspassword.write);
  }, []);

  const handleFilter = () => { };
  const handleSearch = () => {
    if (searchData === '') {
      setTempUsers(users);
    } else {
      const usersSearch = [];
      users.forEach((user) => {
        if (
          (user.name && user.name.toUpperCase().includes(searchData.toUpperCase())) ||
          (user.email && user.email.toUpperCase().includes(searchData.toUpperCase())) ||
          (user.cpfcnpj && user.cpfcnpj.toUpperCase().includes(searchData.toUpperCase()))
        ) {
          usersSearch.push(user);
        }
      })
      setTempUsers(usersSearch);
    }
  };

  const handleSearchData = (e) => {
    setSearchData(e.target.value);
  };

  return (
    <Page className={classes.root} title="Usuários">
      <Header permission={permission} />
      <SearchBar
        onFilter={handleFilter}
        onSearch={handleSearch}
        onSearchData={handleSearchData}
        searchData={searchData}
      />
      {tempUsers && (
        <Results
          permission={permission}
          className={classes.results}
          data={tempUsers}
          onRemoveButton={handleClickOpen}
        />
      )}
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleClose}
        open={open}>
        <DialogTitle id="alert-dialog-title">{'Remover'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja remover o acesso ao Gera Senha deste usuário?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Não
          </Button>
          <Button autoFocus color="primary" onClick={removeUserPermission}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default UserPassword;
