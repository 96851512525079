import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';

import getInitials from 'utils/getInitials';
import dateFormat from 'utils/dateformat';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  autButton: {
    marginTop: '5px',
    borderColor: '#2D462D',
    color: '#679D64',
    marginLeft: '10px',
    background: 'transparent'
  },
  rmvButton: {
    borderColor: '#FE2020',
    color: '#FE2020',
    marginLeft: '10px',
    background: 'transparent',
    marginTop: '5px'
  },
  red: {
    background: 'red'
  },
  green: {
    background: 'green'
  }
}));

const Results = (props) => {
  const {
    className,
    data,
    onAuthorizeButton,
    onRemoveButton,
    permission,
    onAuthorizeGeneratePassword,
    ...rest
  } = props;

  const classes = useStyles();

  const [selectedData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {data.length} registros encontrados. Página {page + 1} de{' '}
        {Math.ceil(data.length / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader title="Usuários" />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell align="center">NOME</TableCell>
                    <TableCell align="center">E-MAIL</TableCell>
                    <TableCell align="center">CPF/CNPJ</TableCell>
                    <TableCell align="center">VERIFICADO</TableCell>
                    <TableCell align="center">E-MAIL VALIDADO</TableCell>
                    <TableCell align="center">FONE</TableCell>
                    <TableCell align="center">EMPRESA</TableCell>
                    <TableCell align="center">ÚLTIMO ACESSO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && data
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((data) => (
                      <TableRow
                        component={RouterLink}
                        to={`/users/${data._id}`}
                        hover
                        key={data.id}
                        selected={selectedData.indexOf(data.id) !== -1}>
                        <TableCell
                          className={
                            data.validated ? classes.green : classes.red
                          }
                        />
                        <TableCell>
                          <div className={classes.nameCell}>
                            <Avatar
                              className={classes.avatar}
                              src={data.avatar}>
                              {getInitials(data.name)}
                            </Avatar>
                            <div>
                              <Typography variant="h6">
                                {data.name && data.name.length > 20 ? (data.name.substring(0, 20) + '...').toUpperCase() : data.name ? data.name.toUpperCase() : '---'}
                              </Typography>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="center">{data.email ? data.email : '---'}</TableCell>
                        <TableCell align="center">{data.cpfcnpj ? data.cpfcnpj : '---'}</TableCell>
                        <TableCell align="center">
                          {data.validated === null
                            ? 'Não'
                            : data.validated === true
                              ? 'Sim'
                              : 'Não'}
                        </TableCell>
                        <TableCell align="center">
                          {data.validationEmail === null
                            ? 'Não'
                            : data.validationEmail === true
                              ? 'Sim'
                              : 'Não'}
                        </TableCell>
                        <TableCell align="center">{data.fone ? data.fone : '---'}</TableCell>
                        <TableCell align="center">
                          {data.company ? data.company : '---'}
                        </TableCell>
                        <TableCell align="center">{data.updatedAt ? dateFormat(data.updatedAt) : '---'}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={data.length}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
            labelRowsPerPage="Linhas por página:"
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 20, 40]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired
};

Results.defaultProps = {
  data: []
};

export default Results;
