import React, { useState } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Alert } from 'components';

import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  TextField,
  colors
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  alert: {
    marginBottom: '30px;'
  }
}));

const GeneralSettings = props => {
  const { profile, className, ...rest } = props;

  const classes = useStyles();

  const { permission } = JSON.parse(localStorage.getItem('userData'));

  const [values, setValues] = useState({
    name: profile.name,
    email: profile.email,
    companyName: profile.companyName,
    companyCNPJ: profile.companyCNPJ
  });

  const [alertMessage] = useState({ message: '', type: '' });

  const handleChange = event => {
    event.persist();

    setValues({
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader title="Perfil" />
        <Divider />
        <CardContent>
          {alertMessage.message && (
            <Alert
              className={classes.alert}
              message={alertMessage.message}
              variant={alertMessage.type}
            />
          )}
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                autoComplete="off"
                fullWidth
                label="Nome"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                autoComplete="off"
                disabled
                fullWidth
                label="E-mail"
                name="email"
                onChange={handleChange}
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                autoComplete="off"
                disabled
                fullWidth
                label="Empresa"
                name="companyName"
                onChange={handleChange}
                value={values.companyName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                autoComplete="off"
                disabled
                fullWidth
                label="CNPJ"
                name="companyCNPJ"
                onChange={handleChange}
                type="text"
                value={values.companyCNPJ}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.saveButton}
            variant="contained"
            disabled={permission.general.write === true ? false : true}
          >
            Salvar
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

GeneralSettings.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object.isRequired
};

export default GeneralSettings;
