import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import dateformat from 'utils/dateformat';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
  },
  content: {
    padding: 0
  },
  head: {
    background: "#DDDDDD"
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  table: {

  }
}));

const Results = props => {
  const { className, data, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardHeader title="Ativações" />
        <Divider />
        <CardContent className={classes.content}>
          <div className={classes.inner}>
            {data && data.map(data => (
              <div key={data._id || ''}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.head}>
                      <TableCell className='col-sm-2' align='center'>SERIAL</TableCell>
                      <TableCell className='col-sm-2' align='center'>SOFTWARE</TableCell>
                      <TableCell className='col-sm-2' align='center'>DATA</TableCell>
                      <TableCell className='col-sm-2' align='center'>E-MAIL</TableCell>
                      <TableCell className='col-sm-2' align='center'>CPF/CNPJ</TableCell>
                      <TableCell className='col-sm-2' align='center'>COPO</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow hover>
                      <TableCell className='col-sm-2' align='center'>{data.serialnumber ? data.serialnumber : '---'}</TableCell>
                      <TableCell className='col-sm-2' align='center'>{data.versaosw ? data.versaosw : '---'}</TableCell>
                      <TableCell className='col-sm-2' align='center'>{data.updatedAt ? dateformat(data.updatedAt, true) : '---'}</TableCell>
                      <TableCell className='col-sm-2' align='center'>{data.email ? data.email : '---'}</TableCell>
                      <TableCell className='col-sm-2' align='center'>{data.user ? data.user.cpfcnpj : '---'}</TableCell>
                      <TableCell className='col-sm-2' align='center'>{data.copo ? data.copo : "Software desatualizado"}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className='col-sm-2' align='center'>LATITUDE</TableCell>
                      <TableCell className='col-sm-2' align='center'>LONGITUDE</TableCell>
                      <TableCell className='col-sm-2' align='center'>ID DO COPO</TableCell>
                      <TableCell className='col-sm-2' align='center'>CHIP WI-FI</TableCell>
                      <TableCell className='col-sm-2' align='center'>PH CALIBRADO</TableCell>
                      <TableCell className='col-sm-2' align='center'>DATA CALIBRAÇÃO</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow hover>
                      <TableCell className='col-sm-2' align='center'>{data.lat ? data.lat : '---'}</TableCell>
                      <TableCell className='col-sm-2' align='center'>{data.lng ? data.lng : '---'}</TableCell>
                      <TableCell className='col-sm-2' align='center'>{data.id ? data.id : '---'}</TableCell>
                      <TableCell className='col-sm-2' align='center'>{data.wf ? data.wf : '---'}</TableCell>
                      <TableCell className='col-sm-2' align='center'>{data.calph ? data.calph : '---'}</TableCell>
                      <TableCell className='col-sm-2' align='center'>{data.dcal ? data.dcal : '---'}</TableCell>
                    </TableRow>
                  </TableBody>

                </Table>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Results;
