import React from 'react';

import PropTypes from 'prop-types';

import { Page } from 'components';
import { Header, NotificationForm } from './components';

import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  },
  indicator: {
    backgroundColor: colors.green[500]
  }
}));

const Notifications = props => {
  const classes = useStyles();

  const user = JSON.parse(localStorage.getItem('userData'))
  if (user) {
    if (user.permission.notifications.read === false) {
      window.location.replace('/home')
    }
  }

  return (
    <Page className={classes.root} title="Notificações">
      <Header />
      <NotificationForm>
      </NotificationForm>
    </Page>
  );
};

Notifications.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default Notifications;
