export const checkAuth = () => {
  let userData = localStorage.getItem('userData');

  if (userData === null) {
    return false;
  }
  if (!JSON.parse(localStorage.getItem('token'))) {
    return false;
  }
  let user = JSON.parse(userData);
  let lastLogDate = user.date;

  const date1 = new Date();
  const date2 = new Date(lastLogDate);
  const diffTime = Math.abs(date2 - date1);

  if (diffTime > 43200 * 1000) {
    alert('Sua sessão expirou. Por favor, faça login novamente.');
    return false;
  }

  return true;
}

export const getToken = () =>
  JSON.parse(localStorage.getItem('token')) != null
    ? JSON.parse(localStorage.getItem('token'))
    : null;

export const logout = () => {
  localStorage.clear();
}
